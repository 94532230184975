.wrapper {
    background: #f0f2f5;
    display: block;
    width: 100%;
    overflow: hidden;
    // height: 100vh; //
}
.profileContainer {
    background-color: #fff;
    border: 1px solid #fff;
    // border-radius: 7px;
    margin: 130px auto;
    padding: 20px;
    display: flex;
    width: 1024px;
    // height: 100vh;
    overflow: hidden;
    .leftMenu {
        margin: 0;
        .menu {
            min-height: 100%;
        }
    }
    .right {
        flex: 1 1;
        padding: 8px 40px;
        margin: 0;
        position: relative;
        .loadingSpin{
            position: absolute;
            left: 50%;
            top:50%;
            transform: translate(-50%,-50%);
        }
        .profiletitle{
            font-size: 20px;
            font-weight: 600;
            color: #000;
            letter-spacing: 1px;
            .regTypetag{
                text-align: center;
                width: 95px;
                height: 22px;
                line-height: 22px;
                border-radius: 8px;
                font-size: 14px;
                margin-left: 5px;  
            }
        }
        .basicWrapper{
            display: flex;
            padding-top: 10px;
            // margin: 0 auto;
            // max-width: 1024px;
            .infoForm{
                margin: 0 30px;
                min-width:400px;
                .setting_form{
                    padding: 0 20px 10px 20px;
                    .setting_form_item{
                          margin-bottom: 10px;
                         .ant-col.ant-form-item-label{
                            margin: 0px 0px 2px 6px;
                            font-weight: 400;
                          }
                         .ant-col.ant-form-item-control{
                             margin-left: 5px;
                          }
                          .ant-form-item-control-input{
                             margin: 0;
                          }
                          .select_input{
                            width: 300px;
                            .ant-select-selection-placeholder{
                               margin: 0;
                            }
                         }
                         .select_inputNumber{
                            width: 300px;
                        }
                         .saveButton{
                            display: block;
                            width: 300px;
                         }
                       }
                   
                }                 
            }
            .userAvatar{
                display: flex;
                margin-left: -200px;
                flex-direction: column;
                position: relative;
                .avatar{
                    color: '#f56a00';
                    background-color:'#fde3cf';
                    margin-top: 10; 
                    margin-bottom:20;
                    margin-bottom: 20px;
                }
                .upload{
                    position: absolute;
                    width: 40px;
                }
                .avataTitle{
                    color: #000;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        .securityList{
            padding-top: 20px;
            .ant-list-item-meta-title{
                color: #000;
                font-weight: 600;
                font-size: 15px;
            }
        }
        .BindingList {
            padding-top: 20px;
            .ant-list-item-meta-title{
                color: #000;
                font-weight: 600;
                font-size: 15px;
            }
            .fb {
                color: #3b5999;
                font-size: 30px;
            }
            .gg {
                color: #dd4b39;
                font-size: 30px;
            }
            .tw {
                color: #55acee;
                font-size: 30px;
            }
            .ant-avatar{
                background: #fff;
            }
        }
        .result{
            margin-top: 70px;
        }
    }
}

@primary-color: #701919;