// 顶部固定导航
// .navbar {
//     background: transparent;
//     height: 72px;
//     position: fixed;
//     right: 0;
//     left: 0;
//     top: 0;
//     z-index: 1000;
// }

.navbar_setTop{
    background-color: #991B1E !important;
    // color: #fff !important;
    border-bottom: none !important;
    box-shadow: none !important;
    .ant-menu {
        a {
            color: #fff;
        }
        span {
            color: #fff;
        }
    }
    &:hover {
        background-color: #fff !important;
        color: #000 !important;
        border-bottom: 1px solid #d8d8d8 !important;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.14)!important;
        .ant-menu {
            a {
                color: #000;
            }
            span {
                color: #000;
            }
        }
    }
}

.navbar_normal{
    display: block;
    background-color: #fff;
    color: #000;
    height: 72px;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.14);
    .nav_url_normal{
        float: right;
        margin: 0;
        list-style: none;
        border-bottom: 0;
        line-height: inherit;
        background-color: transparent;
        li{
            float: left;
            list-style: none;
            display: inline-block;
            position: relative;
            top: 4px;
            a{
                p {
                    color: #555;
                    margin-bottom: 0;
                    font-size: 16px;
                    display: block;
                    cursor: pointer;
                    text-align: center;
                }
            }
            .dropdown_outside{
                color: #555;
                font-size: 15px;
                display: block;
            }
            .new{
                color: #555;
                font-size: 15px;
                display: block;
                &:after{
                    content: 'new';
                    position: absolute;
                    right: -19px;
                    top: -11px;
                    padding: 1px 8px;
                    border-radius: 4px;
                    background-color: #ef3e3e;
                    color: #fff;
                    font-size: 10px;
                    transform: rotate(18deg);
                }
            } 
        }
        /* .last{
            margin-right: 0 !important;
            @media (max-width:1279px) and (min-width:768px) {
                display: none;
            }
        } */
        /* @media (max-width:1279px) and (min-width:768px) {
            li{
                margin-right: 20px;
            }
        } */
            :global{
                .ant-menu {
                    line-height: 1.42857143;
                    color: #555;
                    // color: #fff;
                    font-size: 14px;
                    text-align: center;
                }
                .main-ant-menu-submenu-title {
                    font-size: 16px;
                    font-weight: 400;
                    color: #555;
                    // color: #fff;
                    padding-bottom: 5px;
                    @media (min-width:1024px) and (max-width:1279px){
                        padding: 0 15px;
                    }
                    a {
                        font-size: 30px;
                    }
                }
                .main-ant-menu-item {
                    font-size: 16px;
                    font-weight: 400;
                    // color: #fff;
                    text-align: center;   
                    padding-bottom: 5px;
                }
                .main-ant-menu-sub {
                    text-align: center;
                    // margin-left: -40px;
                }  
            } 
        }
}
.navbar_inner_con {
    width: 100%;
    max-width: 1150px;
    margin: auto;
    height: 72px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .r {
        display: flex;
        align-items: center;
        ul {
            font-size: 16px;
            // color: #fff;
        }
    }
    .r_mobile{
        margin-right: 20px;
        font-size: 25px;
        color: #903535; 
    }
    .r_mobile_index{
        margin-right: 20px;
        font-size: 25px;
        color: #fff; 
    }
}
.mobile_nav_drawer_footer{
    text-align: center;
}

.ant-menu-vertical{
    border-right: none;
}

.ant-drawer-content-wrapper{
    width: 180px !important;
}


.logo_con_normal{
    /*  background: transparent;    */
    font-size: 20px;
    display: block;
    margin-left: 20px;
}
.logo_active_normal{
    height: 53px;
    display: block;
}

.header{
    // height: 80px;
    width: 100%;
    display: block;
    // background-color: #fff;
    // color: #000;
    // height: 72px;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1000;
    // overflow: hidden;
    .header_inner{
        width: 100%;
        max-width: 1150px;
        margin: auto;
        height: 100%;
        position: relative;
        .title{
            float: left;
            padding-top: 45px;
            h1{
                font-family: Candal;
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 28px;
                color: #555;;
            }
            h6{
                font-family: Futura;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 9px;
                // text-align: center;
                letter-spacing: 1.64474px;
                text-transform: uppercase;
                color: #555;;
                font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
        }
        ul{
            list-style: none;
            padding-top: 62px;
            display:flex;
            float: right;
            // margin: 0 15px 0 0;
            // width: 175px;
            height: 75px;
            li{
                display: block;
                // color: #FFFFFF;
                margin-right: 65px;
                // max-width: 150px;
                // width: 100%;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
            }
            .loginBtn{
                background-color: #FF8F67;
                border-color: #FF8F67;
                width: 110px;
                height: 35px;
                .loginspan{
                    color: #FF8F67;
                    margin-left: 10px;
                    font-weight: 400;
                }
            }
            .loginIcon{
                color: #fff;
            }
            .avatar{
                cursor: pointer;
            }
        }
    }
}
@primary-color: #701919;