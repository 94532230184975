.sub_container {
  padding: 72px 40px;
  max-width: 1460px;
  margin: 0 auto;
  min-height: calc(100vh - 320px);
  @media (max-width: 549px) {
      padding: 72px 15px;
  }
  position: relative;
  .sub_bg{
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 150px;
    display: none;
    // width: 503.323px;
    // height: 170.507px;
    // transform: rotate(4.932deg);
  }
  .sub_header {
    justify-content: center!important;
    width: 80%;
    margin: auto;
    margin-bottom:20px;
    margin-top: 130px;
    text-align: center;
    @media (max-width: 549px) {
        flex-direction: column-reverse;
        width: 100%;
        margin-top: 80px;
    }
    .sub_title {
      font-size: 45px;
      color: #000;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
      letter-spacing: 2px;
      word-spacing: 3px;
      font-weight: 700;
      @media (max-width: 549px) {
          font-size: 25px;
          margin-top: 0px;
          margin-bottom: 0px;
      }
      img {
        width: 85px;
        height: 71px;
        flex-shrink: 0;
        margin-right: 30px;
        @media (max-width: 549px) {
          width: 55px;
          height: 45px;
          margin-right: 10px;
        }
      }
    }
    .sub_des {
        margin: 40px 0px;
        font-size: 18px;
        color: #a3a3a3;
        // max-width: 800px;
        font-weight: 400;
        @media (max-width: 549px) {
            font-size: 16px;
        }
    }
  } 
  .sub_plan {
    display: flex;
    justify-content: center;
    @media (max-width: 1080px) {
      width: 100%;
      flex-direction: column;
    }
    .sub_card {
      .summer_sale_tag {
        width: 180px;
        position: absolute;
        top: -2px;
        left: -1px;
      }
      flex: 0 0 31%;
      margin-bottom: 15px;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      height: 100%!important;
      color: #566379!important;
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      // border: 2px solid #B84841;
      background: #FFF;
      // border: 1px solid rgba(90,122,190,.12)!important;
      // border-radius: 0.5rem;
      // box-shadow: 0 3px 0 0 rgba(90,122,190,.12);
      // background-color: #fff;
      transition: box-shadow .3s,-webkit-box-shadow .3s;
      padding: 3rem!important;
      -webkit-box-pack: justify!important;
      justify-content: space-between!important;
      @media (max-width: 1080px) {
        flex: 100%;
      }
      .sub_card_top {
        margin-top: 10px;
        text-align: center!important;
        margin-bottom: 1.8rem!important;
        color: #566379!important;
        img {
          width: 57px;
          margin-bottom: 25px;
        }
        h4 {
          // font-size: 1.333rem;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span {
            font-size: 23px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        p {
          color: #7c899f!important;
          font-size: 16px;
        }
        .sub_price{
          line-height: 44px !important;
          display: flex;
          justify-content: center !important;
          align-items: center !important;
          sup {
            font-size: 22px;
            color: #333 !important;
            vertical-align: middle!important;
            margin-right: 3px;
          } 
          span {
            line-height: 44px !important;
            font-size: 48px;
            color: #333 !important;
          }
          small {
            margin-left: 10px;
            line-height: 44px !important;
            vertical-align: bottom !important;
            font-size: 20px;
            color: #333 !important;
          }
        }
        hr {
          margin-top: 1rem;
          margin-bottom: 1rem;
          border: 0;
          border-top: 1px solid rgba(0,0,0,.1);
        }
      }
      ul {
        padding-left: 0;
        list-style: none;
        margin-top: 0;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        li {
          display: flex;
          p {
            font-size: 16px;
          }
          img {
            width: 16px;
            margin-right: 0.7rem!important;
            vertical-align: middle;
          }
        }
      }
    }
    .sub_card:nth-child(odd) {
      background: #FFF7F6;
      border: 1px solid #fff4f4 !important;
      z-index: 1;
    }
    .sub_card:nth-child(even) {
      // background: #FFF7F6;
      // border: 2px solid #B84841 !important;
      box-shadow: 0px 1px 10px 10px rgba(68, 68, 68, 0.12);
      z-index: 2;
    }
    
    .subscribeBtn {
      width: 100%;
      height: 42px;
      font-size: 18px;
      line-height: 21px;
      padding: 8px 24px;
      color: #fff;
      border-color: #cb4747;
      background-color: #cb4747;
      border-radius: 3px;
      outline: none;
      margin-right: 30px;
      transition: 0.5s;
      text-align: center;
      &:hover {
        height: 45px;
        font-size: 21px;
        background-color: #fff;
        color: #cb4747;;
      }
    }
  }
  .faq {
    width: 100%;
    max-width: 985px;
    margin: 100px auto 100px;
    @media (max-width: 549px) {
      margin-top: 50px
    }
    .title {
      text-align: center;
      .sub_title {
        font-size: 40px;
        color: #000;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
        letter-spacing: 2px;
        word-spacing: 3px;
        font-weight: 400;
        @media (max-width: 549px) {
            font-size: 25px;
            margin-top: 0px;
        }
        img {
          width: 60px;
          height: 60px;
          flex-shrink: 0;
          margin-right: 20px;
          @media (max-width: 549px) {
            width: 55px;
            height: 55px;
            margin-right: 10px;
          }
        }
      }
      .sub_des {
        margin: 40px 0px;
        font-size: 18px;
        color: #a3a3a3;
        // max-width: 800px;
        font-weight: 400;
        @media (max-width: 549px) {
            font-size: 16px;
        }
      }
    }
    .ant-collapse {
      background-color: #fff;
      margin-top: 60px;
    }
    .ant-collapse-item {
      width: 100%;
      border: none;
      padding: 12px 15px;
      margin: 0 0 10px;
      box-shadow: 0 0 2px rgba(0,0,0,0.15);
      border-radius: 2px;
      background: #fff;
      position: relative;
      font-size: 16px;
    }
    .ant-collapse-arrow {
      color: #B84841 !important;
    }
    .ant-collapse-content {
      padding-left: 25px;
    }
  }
  .need_help {
    width: 100%;
    max-width: 985px;
    margin: 100px auto 100px;
    .title {
      text-align: center;
      .sub_title {
        font-size: 32px;
        color: #222;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 4px;
        letter-spacing: 2px;
        word-spacing: 3px;
        font-weight: 400;
        @media (max-width: 549px) {
            font-size: 25px;
            margin-top: 0px;
        }
      }
      .sub_des {
        margin: 15px 0px;
        font-size: 18px;
        color: #5f5f5f;
        // max-width: 800px;
        font-weight: 400;
        @media (max-width: 549px) {
            font-size: 16px;
        }
      }
    }
  }
}
@primary-color: #701919;