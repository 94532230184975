.footer-container{
    background: #f1ecec;
    .footer {
        display: flex;
        color: #6c7684;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        // flex-direction: column;
        width: 1200px;
        height: 260px;
        margin: 0 auto;
        padding-top: 41px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        justify-content: space-between;
        .logo {
            img {
                width: 60px;
                margin-right: 15px;
            }
            span {
                color: #701919;
                line-height: 25px;
                font-size: 20px;
                font-weight: 400;
                // font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            .privacy{
                margin-top: 10px;
                padding-left: 80px;
                text-align: left;
                p{  
                    cursor: pointer;
                }
            }
        }
        .contact {
            margin-top: 10px;
            padding-left: 40px;
            text-align: left;
            .title{
                font-size: 18px;
                font-weight: 400;
                color: #6c7684;
                line-height: 16px;
                // font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            .content {
                margin-top: 30px;
                div {
                    margin-bottom: 18px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #6c7684;
                    line-height: 17px;
                }
            }
        }
        .announce{
            margin-top: 10px;
            padding-left: 40px;
            text-align: left;
            .title{
                font-size: 18px;
                font-weight: 400;
                color: #6c7684;
                line-height: 16px;
                // font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            .content {
                margin-top: 30px;
                div {
                    width: 290px;
                    margin-bottom: 18px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #6c7684;
                    line-height: 17px;
                }
                .content_link{
                    cursor: pointer;
                }
                .content_link:hover{
                    color: #701919;
                    text-decoration: underline;
                }
            }
        }
    }
    
    .footer-fix{
        position: fixed;
        bottom: 0;
        width: 100%;
        color: #6c7684;
        height: 60px;
    }
    
    .copyright{
        align-items: center;
        text-align: center;
        letter-spacing: 0.03em;
        color: #6c7684;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 60px;
    }    
}
.footer-container-ca{
    background: #f1ecec;
    padding-top: 30px;
    padding-bottom: 90px;
    @media (max-width: 549px) {
        padding: 20px 0px;
        margin-top: 30px;
    }
    .footer {
        display: flex;
        color: #6c7684;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        // flex-direction: column;
        width: 1200px;
        height: 260px;
        margin: 0 auto;
        padding-top: 41px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        justify-content: space-between;
        .logo {
            img {
                width: 60px;
                margin-right: 15px;
            }
            span {
                color: #701919;
                line-height: 25px;
                font-size: 20px;
                font-weight: 400;
                // font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            .privacy{
                margin-top: 10px;
                padding-left: 5px;
                text-align: left;
                p{  
                    cursor: pointer;
                    width: 280px;
                }
            }
        }
        .contact {
            margin-top: 10px;
            padding-left: 40px;
            text-align: left;
            .title{
                font-size: 20px;
                font-weight: 500;
                color: #6c7684;
                line-height: 16px;
                color:#701919;
                // font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            .content {
                margin-top: 30px;
                p {
                    margin-bottom: 30px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #6c7684;
                    line-height: 17px;
                    cursor: pointer;
                    &:hover{
                        color: #701919;
                    }
                    a {
                        color: #6c7684;
                        &:hover{
                            color: #701919;
                        }
                    }
                }
            }
        }
        .announce{
            margin-top: 10px;
            padding-left: 40px;
            text-align: left;
            .title{
                font-size: 20px;
                font-weight: 500;
                color:#701919;
                line-height: 16px;
                // color: 
                // font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            .content {
                margin-top: 30px;
                span {
                    margin-right: 15px;
                    font-size: 18px;
                    color:#701919;
                }
                div {
                    width: 290px;
                    margin-bottom: 30px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #6c7684;
                    line-height: 17px;
                }
                .content_link{
                    cursor: pointer;
                }
                .content_link:hover{
                    color: #701919;
                    text-decoration: underline;
                }
            }
        }
    }
    
    .footer-fix{
        position: fixed;
        bottom: 0;
        width: 100%;
        color: #6c7684;
        height: 60px;
    }
    
    .copyright{
        align-items: center;
        text-align: center;
        letter-spacing: 0.03em;
        color: #6c7684;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
    }    
}

@primary-color: #701919;