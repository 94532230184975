.loadingWrapper{
    position: relative;
    height: 100vh;
    .loading{
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
        // display: flex; 
        // justify-content: center; 
        // align-items: center;
    }
}
@primary-color: #701919;