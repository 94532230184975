.pkgQrcodeCon {
  :global {
      .main-ant-modal-body {
          padding: 15px 25px;
      }
  }
  .container {
      margin: -24px;
      background-color: #f6f6f6;
      padding: 40px 40px 30px;
  }
  .right {
      /* width: 380px; */
      background: #fff;
      margin: 0 auto;
      width: 300px;
      padding: 0 25px;
      overflow: hidden;
      border-radius: 5px;
      p {
          color: #333;
          font-size: 16px;
          margin-top: 20px;
          line-height: 30px;
      }
      img {
          width: 160px;
          height: 160px;
      }
      .con {
          margin-top: 0;
          text-align: center;
      }
      @media (max-width: 549px) {
          width: 100%;
      }
  }
  .methodDes {
      color: #333;
      font-size: 14px;
      margin: 0 0 20px 10px;
      font-size: 15px;
      color: #666;
      i {
          font-size: 17px;
          position: relative;
          top: 1px;
          color: #000;
      }
      @media (max-width: 1023px) {
          margin: 10px 0 15px 0;
      }
  }
  .methodTitle {
      font-size: 20px;
      padding: 20px 0;
      position: relative;
      strong {
          display: inline-block;
          border-bottom: 3px solid #3d95f9;
          padding: 0 5px 5px 5px;
      }
      i.wechat {
          color: green;
          font-size: 100px;
          position: absolute;
          opacity: 0.2;
          right: -65px;
          top: -15px;
      }
      i.form {
          color: #3d95f9;
          font-size: 78px;
          position: absolute;
          opacity: 0.2;
          right: -37px;
          top: -7px;
      }
  }
}
@primary-color: #701919;