.homePage_wrapper{
    // width: 100%;
    // background: rgb(253, 232, 250);
    background: #fff;
    height: 100vh;
    margin: 0;
    padding: 0;
    .sec-1 {
        // background-image: linear-gradient(rgb(255, 234, 206),rgba(154, 10, 10, 0.55));
        // background-image: linear-gradient(to right,#B82F25,#ec564bf7);
        
        background-color: #991B1E;
        background-size: cover;
        background-position: 50%;
        max-height:790px;
        position: relative;
        width: 100%;
        margin: 60px auto;
        @media (max-width:768px) {
            margin-bottom: 30px;
        }
        .front-page-img-vector{
            position: absolute;
            bottom: 0;
            right: 0;
            @media (max-width: 1550px) {
                height: 410px;
            }
            @media (max-width: 549px) {
                height: 130px;
            }
        }
        .front-page-img-vector2{
            position: absolute;
            bottom: 0px;
            right: -30px;
            @media (max-width: 1550px) {
                height: 540px;
            }
            @media (max-width: 549px) {
                height: 180px;
                right: 0px;
            }
        }
        .welcome-container{
            // background-color: #991B1E;
            // background-image: linear-gradient(to right,#B82F25,#ec564bf7);
            position: relative;
            max-width: 1440px;
            margin: auto;
            height: 790px;
            padding: 200px 150px;
            @media (max-width: 549px) {
                height: 320px;
                padding: 50px 15px;
            }
            h1 {
                color: #fff;
                line-height: 1.4;
                font-size: 65px;
                text-transform: uppercase;
                font-weight:800;
                letter-spacing: 8px;
                margin-bottom: 30px;
                font-weight: 700;
                span {
                    font-weight: 700;
                    font-size: 80px;
                    @media (max-width: 549px) {
                        font-size: 30px;
                    } 
                    letter-spacing: 1px;
                }
                @media (max-width: 549px) {
                    font-size: 25px;
                    line-height: 0.8;
                    letter-spacing: 3px;
                } 
            }
            h4 {
                font-size: 40px;
                margin-bottom: 25px;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 1.3;
                color: #fff;
                letter-spacing: 8px;
                font-weight: 700;
                @media (max-width: 549px) {
                    font-size: 20px;
                    letter-spacing: 1px;
                } 
            }
            h5 {
                color: #FFB1A5;
                font-size: 35px;
                font-weight: 700;
                letter-spacing: 6px;
                @media (max-width: 549px) {
                    font-size: 20px;
                    letter-spacing: 2px;
                } 
            }
            .front-page-img{
                width: 100%;
                height: 100%;
                // @media (max-width: 768px) {
                //     height: 210px;
                // }
            }
            .front-page-img-mb{
                height: 100%;
                width: 100%;
            }
        }
        // .welcome-s1{
        //     max-width: 435px;
        //     font-family: 'Inter';
        //     font-style: normal;
        //     font-weight: 600;
        //     font-size: 40px;
        //     line-height: 70px;
        //     /* identical to box height, or 175% */
        //     color: #334481;
        //     // color: #fff;
        //     display: block;
        // }
        // .welcome-s2{
        //     display: block;
        //     max-width: 515px;
        //     margin-left: 150px;
        //     font-family: 'Inter';
        //     font-style: normal;
        //     font-weight: 600;
        //     font-size: 40px;
        //     line-height: 70px;
        //     /* identical to box height, or 175% */
        //     color: #334481;
        //     // color: #fff;
        //     .welcome-brand{
        //         color:#98171D;
        //     }
        // }
        // .welcome-women{
        //     @media (min-width: 1351px) {
        //         position: absolute;
        //         width: 832px;
        //         height: 478px;
        //         left: -20%;
        //         top: 118px;
        //     }
        //     @media (max-width: 1350px) {
        //         position: absolute;
        //         width: 453px;
        //         height: 243px;
        //         left: 0px;
        //         top: 357px;
        //     }
        // }
        // .welcome-man{
        //     @media (min-width: 1351px) {
        //         position: absolute;
        //         width: 496px;
        //         height: 443px;
        //         right: 0px;
        //         top: 153px;
        //     }
        //     @media (max-width: 1350px) {
        //         position: absolute;
        //         width: 300px;
        //         height: 243px;
        //         right: 10px;
        //         top: 357px;
        //     }
        // }
    }
    .sec-2 {
        .header-consular {
            max-width: 1150px;
            margin: 40px auto 42px;
            display: flex;
            position: relative;
            @media (max-width:768px) {
                margin-left: 15px;
            }
            .img {
                // width: 65px;
                // // height: 65px;
                font-size: 40px; 
                margin-right: 15px;
                color: #ce3636;
                @media (max-width: 768px) {
                    font-size: 35px;
                    margin-right: 10px;
                }
            }
            div {
                .p-title {
                    font-size: 27px;
                    font-weight: 400;
                    margin: 0 0 5px;
                    line-height: 42px;
                    color: #ce3636;
                    // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    @media (max-width:768px) {
                        font-size: 22px;
                    }
                }
                .p-title-desc {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #afafaf;
                    margin: 0;
                }
            }
        }
        .home-consular {
            margin: 20px auto 42px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 1150px;
            // margin: auto;
            height: auto;
            .courseCard {
                width: 265px;
                height: 240px;
                border-radius: 8px;
                // box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
                box-shadow: 0 4px 30px 0 rgba(221, 223, 225, 0.8);
                overflow: hidden;
                background-color: #fff;
                margin-bottom: 50px;
                position: relative;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);
                }
            }
            .ignore{
                opacity: 0;
                @media (max-width: 768px) {
                    display: none !important;
                    opacity: 1;
                }
            }
            .courseCardMore {
                margin-bottom: 50px;
                cursor: pointer;
                box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
                border-radius: 8px;
                &:hover {
                    box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);
                }
            }
            .coreCardDesContainer {
                padding: 15px 15px 14px;
                position: relative;
            }
            .imgCourseCon {
                width: 100%;
                height: 145px;
            }
            .imgCourse {
                height: 145px;
                width: auto;
                right: 0;
                position: absolute;
            }
            .iconCollection {
                font-size: 18px;
                vertical-align: text-top;
            }
            .courseDes {
                display: inline;
                font-size: 15px;
                font-weight: 400;
                line-height: 21px;
                color: #363636;
                margin-left: 8px;
            }
            .courseTitle {
                position: absolute;
                top: 100px;
                right: 10px;
                font-size: 17px;
                line-height: 17px;
                color: #fff;
                // color: #000;
                font-weight: 700;
                background: #7f2626;
                border-radius: 10px;
                padding: 8px;
            }
            .courseTitle2 {
                position: absolute;
                top: 93px;
                left: 24px;
                font-size: 16px;
                line-height: 25px;
                color: #fff;
                font-weight: 400;
            }
            .courseTitleMb {
                display: none;
            }
            .courseTags {
                display: flex;
                flex-wrap: wrap;
            }
            .courseTag {
                font-size: 14px;
                line-height: 14px;
                font-weight: 400;
                color: #656565;
                // color: #8b1810;
                margin-right: 6px;
                margin-bottom: 6px;
                background-color: #f7f7f7;
                // background-color: #ffebe9;
                padding: 6px 12px;
                border-radius: 4px;
            }
            .imgStar {
                vertical-align: bottom;
                width: 22px;
                margin-left: 10px;
            }
            .cardConMb {
                padding-left: 25px;
                padding-right: 25px;
            }
            /* .courseCardMore {
                width: 320px;
                height: 400px;
                border-radius: 8px;
                background-color: transparent;
                border: 2px dashed #979797;
                padding: 148px 98px;
            } */
            .moreCourseTitle {
                font-size: 24px;
                font-weight: 400;
                color: #565656;
                line-height: 50px;
                text-align: center;
            }
            .moreCourseDes {
                font-size: 20px;
                font-weight: 400;
                color: #565656;
                line-height: 50px;
                margin-bottom: 4px;
            }
            .coursesBackground {
                height: 1530px;
                background-color: #F6F8FB;
                width: 100%;
                position: absolute;
                z-index: 0;
                overflow: hidden;
                &:after {
                    content: '';
                    position: absolute;
                    background: #fff;
                    width: 6000px;
                    height: 520px;
                    transform: rotate(-7deg);
                    top: 1340px;
                    left: calc((3000px - 50vw) * -1);
                }
            }
            .cardBottom {
                margin-top: 13px;
            }
            .imgArrow {
                margin-left: 6px;
            }
            .priceSign {
                display: inline;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #363636;
            }
            .introPrice {
                display: inline;
                font-size: 20px;
                font-weight: 700;
                line-height: 28px;
                color: #363636;
                margin-left: 5px;
            }
            .originPrice {
                display: inline;
                font-size: 15px;
                font-weight: 400;
                line-height: 21px;
                color: #C9C9C9;
                margin-left: 8px;
                text-decoration: line-through;
            }
            .gotoCourse {
                display: inline-block;
                float: right;
                width: 96px;
                height: 36px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #327DFF;
                padding: 8px 11px;
                &:hover {
                    background-color: #f6f6f6;
                    border-radius: 4px;
                }
            }
            .cardDvider {
                border: 1px solid #E1E1E1;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            .courseCardEmpty {
                display: none;
                width: 265px;
                height: 0;
                margin-bottom: 50px;
            }
            .imgMore {
                box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
                border-radius: 8px;
                width: 265px;
            }
            @media (max-width: 1279px) and (min-width: 550px) {
                width: 900px;
                margin: auto;
                .courseCardEmpty {
                    display: block;
                }
            }
            @media (max-width: 549px) {
                margin-top: 35px;
                width: 100%;
                display: block;
                height: auto;
                .courseCard {
                    display: block;
                    width: 100%;
                    max-width: 335px;
                    height: auto;
                    position: relative;
                    margin: auto auto 25px;
                    overflow: hidden;
                }
                .courseTitle {
                    top: 49px;
                    font-size: 22px;
                    line-height: 30px;
                }
                .courseTitle2 {
                    top: 84px;
                }
                .coreCardDesContainer {
                    display: none;
                }
                .courseCardEmpty {
                    display: none;
                }
                .imgCourseCon {
                    height: 152px;
                }
                .imgCourse {
                    height: 152px;
                }
                .courseCardMore {
                    display: none;
                }
            }
        }
    }
    .sec-3 {
        margin-bottom: 80px;
        .header-table {
            max-width: 1150px;
            margin: 40px auto 42px;
            display: flex;
            position: relative;
            @media (max-width:768px) {
                margin-left: 15px;
            }
            .img {
                // width: 65px;
                // // height: 65px;
                font-size: 40px; 
                margin-right: 15px;
                color: #ce3636;
                @media (max-width: 768px) {
                    font-size: 35px;
                    margin-right: 10px;
                }
            }
            div {
                .p-title {
                    font-size: 27px;
                    font-weight: 400;
                    margin: 0 0 5px;
                    line-height: 42px;
                    color: #ce3636;
                    // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    @media (max-width:768px) {
                        font-size: 22px;
                    }
                }
                .p-title-desc {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #afafaf;
                    margin: 0;
                }
            }
        }
        .home-table {
            box-shadow: 0 4px 30px 0 rgba(238,242,245,.8);
            border-radius: 10px;
            padding: 20px;
            @media (min-width: 1081px) {
                margin: 20px auto 42px;
                position: relative;
                max-width: 1150px;
                border: 1px solid #efefef;
            }
            @media (max-width: 1080px) {
                display: none;
            }
        }
        .home-table-mb {
            @media (min-width: 1081px) {
                display: none;
            }
            @media (max-width: 1080px) {
                padding: 0px;
                margin: 0 30px;
                border: 1px dashed #ae1919;
                // box-shadow: 5px 10px #fbd7d7;
                border-radius: 8px;
            }
        }
    }
    .sec-4 {
        .header-flow {
            max-width: 1150px;
            margin: 40px auto 42px;
            display: flex;
            position: relative;
            @media (max-width:768px) {
                margin-left: 15px;
            }
            .img {
                // width: 65px;
                // // height: 65px;
                font-size: 40px; 
                margin-right: 15px;
                color: #ce3636;
                @media (max-width: 768px) {
                    font-size: 35px;
                    margin-right: 10px;
                }
            }
            div {
                .p-title {
                    font-size: 27px;
                    font-weight: 400;
                    margin: 0 0 5px;
                    line-height: 42px;
                    color: #ce3636;
                    // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    @media (max-width:768px) {
                        font-size: 22px;
                    }
                }
                .p-title-desc {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #afafaf;
                    margin: 0;
                }
            }
        }
        .flow-container{
            max-width: 900px;
            margin: 40px auto 50px;
            display: flex;
            flex-direction: column;
            z-index: 1;
            position: relative;
            @media (max-width: 768px) {
                margin:10px 30px;
            }
            .flow_bg{
                position: absolute;
                z-index: -1;
                top: -50px;
                @media (max-width: 768px) {
                    top: 0px;
                    width: 300px;
                    left: 15px;
                }
            }
            .flow-card{
                margin-top: 25px;
                display: flex;
                max-width: 850px;
                justify-content: space-between;
                @media (max-width: 768px) {
                    flex-direction: column;;
                }
                .flow-left{
                    @media (max-width: 768px) {
                        margin: 0 auto;
                    }
                    .step{
                        img{
                            width: 60px;
                            height: 60px;
                            margin-right: 50px;
                            
                        }
                        span{
                            font-weight: 00;
                            font-size: 22px;
                            line-height: 25px;
                            color: #272727;
                            // color: #202020;
                            .step-icon {
                                margin-right: 10px;
                                font-size: 28px;
                                // color: #ED6A38;
                                color: #ce3636;
                            }
                        }
                    }
                    .arrow{
                        margin-top: 30px;
                        margin-left: 45px;
                        img{
                            width: 50px;
                            margin: 20px 10px;
                            @media (max-width: 768px) {
                                width: 30px;
                                margin: 0;
                            }
                        }
                        @media (max-width: 768px) {
                            margin-top: 20px;
                            margin-left: 45px;
                            margin-bottom: 20px;
                        }
                    }
                    .arrow-hide{
                        display: None;
                        @media (max-width: 768px) {
                           display: block;
                        }
                    }
                }
                .flow-right{
                    width:600px;
                    @media (max-width: 768px) {
                        width: 100%
                     }
                    div {
                        padding: 2px 15px;
                        font-size: 18px;
                        line-height: 40px;
                        font-weight: 400;
                        color: #626262;
                        border-radius: 5px;
                        // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
                        box-shadow: 0 4px 30px 0 rgba(238,242,245,.8);
                        background: #fefefe;
                        border-left: 5px solid;
                        // border-color: #ED6A38;
                        border-color: #ce3636;
                        @media (max-width: 768px) {
                            font-size: 16px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
    .sec-5 {
        .success-header {
            max-width: 1150px;
            margin: 40px auto 10px;
            display: flex;
            position: relative;
            @media (max-width:768px) {
                margin-left: 15px;
            }
            .img {
                // width: 65px;
                // // height: 65px;
                font-size: 40px; 
                margin-right: 15px;
                color: #ce3636;
                @media (max-width: 768px) {
                    font-size: 35px;
                    margin-right: 10px;
                }
            }
            .header-l {
                flex: 1;
                .p-title {
                    font-size: 27px;
                    font-weight: 400;
                    margin: 0 0 5px;
                    line-height: 42px;
                    color: #ce3636;
                    // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    @media (max-width:768px) {
                        font-size: 22px;
                    }
                }
                .p-title-desc {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #afafaf;
                    margin: 0;
                }
            }
            .header-r {
                margin-right: 120px;
                .p-title {
                    font-size: 27px;
                    font-weight: 400;
                    margin: 0 0 5px;
                    line-height: 42px;
                    color: #ce3636;
                    // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    @media (max-width:768px) {
                        font-size: 22px;
                    }
                }
                .p-title-desc {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #afafaf;
                    margin: 0;
                }
            }
        }
        .quanfangwei{
            margin: 10px auto;
            max-width: 980px;
            img {
                max-width: 980px;
                @media (max-width:768px) {
                    width: 100%;
                    margin: 20px 20px;
                }
            }
        }
        .success-container{
            position: relative;
            max-width: 1150px;
            margin: 20px auto 60px;
            scroll-behavior: smooth;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: flex;
            justify-content: space-between;
            @media (max-width: 768px) {
                flex-direction: column;
                margin-top: 10px;
                max-width: 400px;
                padding: 15px;
                margin-bottom: 0px;
            }
            // margin-top: 60px;
            .module-l {
                flex: 1;
                .module-header {
                    display: flex;
                    .img {
                        // width: 65px;
                        // // height: 65px;
                        font-size: 40px; 
                        margin-right: 15px;
                        color: #ce3636;
                        @media (max-width: 768px) {
                            font-size: 35px;
                            margin-right: 10px;
                        }
                    }
                    // justify-content: space-between;
                    .p-title {
                        font-size: 27px;
                        font-weight: 400;
                        margin: 0 0 5px;
                        line-height: 42px;
                        color: #ce3636;
                        // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        @media (max-width:768px) {
                            font-size: 22px;
                        }
                    }
                    .p-title-desc {
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        color: #afafaf;
                        margin: 0;
                    }
                }
                .module-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 16px;
                    @media (max-width: 768px) {
                        flex-direction: column;
                        flex-wrap: none;
                    }
                    .list-product {
                        margin-bottom: 24px;
                        // cursor: pointer;
                        width: 410px;
                        height: 180px;
                        background: #fff;
                        box-shadow: 0 4px 30px rgba(238,242,245,.8);
                        border-radius: 8px;
                        transition: box-shadow .2s linear;
                        @media (max-width: 768px) {
                            width: 100%;
                        }
                        .content {
                            display: flex;
                            padding: 16px 12px 0 18px;
                            .pic {
                                flex-shrink: 0;
                                position: relative;
                                // background: #eee;
                                // background: #fdefed;
                                border-radius: 6px;
                                width: 100px;
                                .visa_type{
                                    margin-top: 30px;
                                    font-size: 28px;
                                }
                                div {
                                    font-size: 23px;
                                    text-align: center;
                                    // line-height: 70px;
                                    // vertical-align: middle;
                                    color: #991B1E;
                                    font-weight: 400;
                                }
                                img {
                                    margin-top: 15px;
                                    width: 100px;
                                    height: 115px;
                                    @media (max-width: 768px) {
                                        margin-top: 15px;
                                        width: 75px;
                                        height: 95px;
                                    }
                                }
                            }
                            .detail {
                                flex: 1;
                                overflow: hidden;
                                margin-left: 18px;
                                .detail_title {
                                    font-size: 20px;
                                    font-weight: 400;
                                    // font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                                    color: #525252;
                                    // line-height: 24px;
                                    cursor: pointer;
                                    transition: color .2s ease;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .detail_summary {
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    padding-top: 8px;
                                    color: #888;
                                }
                                .detail_username {
                                    padding-top: 8px;
                                    color: #b2b2b2;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .time_wrap {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding-top: 30px;
                                    .reserve_time {
                                        font-size: 16px;
                                        font-weight: 400;
                                        // color: #fa1919;
                                        line-height: 20px;
                                        @media (max-width: 768px) {
                                            font-size: 15px;
                                        }
                                    }
                                    .plan_name {
                                        height: 28px;
                                        padding: 0 18px;
                                        line-height: 28px;
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #C6473E;
                                        background: #FFEFEC;
                                        border-radius: 14px;
                                        @media (max-width: 768px) {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .list-product:nth-child(odd) {
                        margin-right: 24px;
                    }
                }
            }
            .module-r {
                margin-left: 24px;
                @media (max-width:768px) {
                    margin-left: 0px;
                }
                .module-header-2 {
                    display: flex;
                    @media (max-width:768px) {
                        margin-top: 30px;
                    }
                    .img {
                        // width: 65px;
                        // // height: 65px;
                        font-size: 40px; 
                        margin-right: 15px;
                        color: #ce3636;
                        @media (max-width: 768px) {
                            font-size: 35px;
                            margin-right: 10px;
                        }
                    }
                    .p-title {
                        font-size: 27px;
                        font-weight: 400;
                        margin: 0 0 5px;
                        line-height: 42px;
                        color: #ce3636;
                        // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                        @media (max-width:768px) {
                            font-size: 22px;
                        }
                    }
                    .p-title-desc {
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        color: #afafaf;
                        margin: 0;
                    }
                }
                .module-list-2 {
                    margin-top: 16px;
                    .list-product-2 {
                        height: 118px;
                        width: 282px;
                        margin-bottom: 15px;
                        padding: 9px;
                        background: #fff;
                        box-shadow: 0 4px 30px 0 rgba(238,242,245,.8);
                        border-radius: 8px;
                        box-sizing: border-box;
                        cursor: pointer;
                        transition: box-shadow .2s linear;
                        &:hover {
                            box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);
                        }
                        @media (max-width:768px) {
                            width:100%;
                        }
                        .content-box {
                            position: relative;
                            padding: 10px 55px 8px 12px;
                            border-radius: 5px;
                            background-color: rgba(238,242,245,.8);
                            height: 100%;
                            .main-title {
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                font-size: 16px;
                                font-weight: 400;
                                color: #353535;
                                // color: #fff;
                                line-height: 21px;
                                transition: color .2s ease;
                            }
                            .sub-title {
                                margin-top: 5px;
                                font-size: 13px;
                                font-weight: 400;
                                color: #757575;
                                line-height: 17px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                        .cb-1{
                            background-image: url('../../asset/img/xhs1.png');
                            color: #fff;
                        }
                        .cb-1-mb{
                            background-image: url('../../asset/img/xhs1-mb.png');
                        }
                        .cb-2{
                            background-image: url('../../asset/img/xhs2.png');
                            // background-image: url('../../asset/img/xhs1.png');
                            color: #fff;
                        }
                        .cb-2-mb{
                            
                            background-image: url('../../asset/img/xhs2-mb.png');
                            // background-image: url('../../asset/img/xhs1-mb.png');
                        }
                        .cb-3{
                            // background-image: url('../../asset/img/xhs1.png');
                            background-image: url('../../asset/img/xhs3.png');
                            color: #fff;
                        }
                        .cb-3-mb{
                            background-image: url('../../asset/img/xhs3-mb.png');
                            // background-image: url('../../asset/img/xhs1-mb.png');
                        }
                    }
                }
            }
        }
    }
}
  
@primary-color: #701919;