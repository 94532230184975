.docs_container {
    padding: 72px 40px;
    max-width: 1460px;
    margin: 0 auto;
    min-height: calc(100vh - 320px);
    @media (max-width: 549px) {
        padding: 72px 30px;
    }
    .team-consult {
        width: 1024px;
        margin: 200px auto 100px;
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
        // flex-direction: column;
        @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
            margin-top: 80px;
        }
        .person-consult-container {
            width: 300px;
            // background: #FFF7F6;
            position: relative;
            padding-bottom: 20px;
            border-radius: 10px;
            // border: 1px solid rgba(159,159,159,0.5);
            box-shadow: 0 0 5px 0 rgba(159,159,159,0.5);
            @media (max-width: 768px) {
                    width: 100%;
                    margin: 0 auto;
                    margin-top: 100px;
                    // padding-left: 20px;
                    // padding-right: 20px;
            }
            .consult_ppl {
                width: 196px;
                height: 220px;
                position: absolute;
                top: -90px;
                left: 50%;
                transform: translate(-50%);
                @media (max-width: 768px) {
                    left: 50%;
                    transform: translate(-50%);
                }
            }
            .consult_contain_top {
                padding-top: 160px;
                text-align: center;
                padding-bottom: 1px;
                p:first-of-type {
                    color: #991B1E;
                    text-align: center;
                    // font-family: Inter;
                    font-size: 20px;
                    // font-style: normal;
                    font-weight: 400;
                    line-height: 10px;
                }
                p:nth-of-type(2) {
                    color: #000;
                    text-align: center;
                    font-size: 24px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 10px;
                }
            }
            ul {
                list-style: none;
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 65px;
                li {
                  display: flex;
                  p {
                    // color: #000;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
            }
            .tag-container {
                text-align: center;
                .tag {
                    font-size: 14px;
                    margin: 6px;
                    padding: 5px;
                    // background-color: #c98788;
                    border-radius: 5px;
                    // color: #991B1E;
                    // color: #fff;
                }
            }
        }
    }
    .docs_header {
        width: 80%;
        margin: auto;
        margin-bottom:20px;
        margin-top: 30px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        @media (max-width: 549px) {
            flex-direction: column-reverse;
            width: 100%;
        }
    }
    .docs_img_event {
        margin-top: 30px;
        height: 300px;
        width: auto;
        @media (max-width: 549px) {
            height: 160px;
        }
    }
    .docs_title {
        font-size: 50px;
        color: #555;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
        @media (max-width: 549px) {
            font-size: 29px;
            margin-top: 0px;
        }
    }
    .docs_des {
        font-size: 18px;
        color: #a3a3a3;
        max-width: 800px;
        font-weight: 400;
        @media (max-width: 549px) {
            font-size: 16px;
        }
    }
    .consult {
        width: 1024px;
        padding-left: 25px;
        padding-right: 25px;
        margin: auto;
        position: relative;
        margin-bottom: 60px;
        height: 100%;
        @media (max-width: 549px) {
            width: 100%;    
            padding-left: 0px;
            padding-right: 0px;
        }
        .container {
            // margin: -15px -25px;
            background-color: #fafafa;
            padding: 40px 40px 30px;
            :global {
                .main-ant-form-item {
                    margin-bottom: 10px;
                }
            }
            .wrap_consult {
                display: flex;
                
            }
            @media (max-width: 1023px) {
                padding: 35px 15px 15px;
                .wrap_consult {
                    display: block;
                }
                .left, .right {
                    width: 100%;
                }
                .right {
                    margin: 20px 0 0;
                    .con {
                        margin: 20px 0 30px;
                    }
                }
            }
        }
        .left {
            width: 450px;
            background: #fff;
            padding: 0 25px;
            overflow: hidden;
            border-radius: 5px;
        }
        .right {
            /* width: 380px; */
            background: #fff;
            margin: 0 0 0 40px;
            // width: 300px;
            padding: 0 25px;
            overflow: hidden;
            border-radius: 5px;
            flex: 1;
            p {
                color: #333;
                font-size: 16px;
                margin-top: 20px;
                line-height: 30px;
            }
            img {
                width: 160px;
                height: 160px;
            }
            .con {
                margin-top: 30px;
                text-align: center;
            }
        }
        .methodDes {
            color: #333;
            font-size: 14px;
            margin: 0 0 20px 10px;
            font-size: 15px;
            color: #666;
            i {
                font-size: 17px;
                position: relative;
                top: 1px;
                color: #000;
            }
            @media (max-width: 1023px) {
                margin: 10px 0 15px 0;
            }
        }
        .methodTitle {
            font-size: 20px;
            padding: 20px 0;
            position: relative;
            font-weight: 400;
            strong {
                display: inline-block;
                border-bottom: 3px solid #3d95f9;
                padding: 0 5px 5px 5px;
            }
            i.wechat {
                color: green;
                font-size: 100px;
                position: absolute;
                opacity: 0.2;
                right: -65px;
                top: -15px;
            }
            i.form {
                color: #3d95f9;
                font-size: 78px;
                position: absolute;
                opacity: 0.2;
                right: -37px;
                top: -7px;
            }
        }
        .formAlert {
            padding: 8px 15px;
            background-color: #e6f7ff;
            margin: 15px 0 20px 0;
        }
    }
    .plan{
        width: 1024px;
        padding-left: 25px;
        padding-right: 25px;
        margin: auto;
        position: relative;
        @media (max-width: 549px) {
            width: 100%;    
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .con_title{
        color:#3D3D3D;
        font-size: 24px;
        font-weight: 400;
        line-height: 70px;
        margin-bottom: 0;
        @media (max-width: 549px) {
            font-size: 24px;
            line-height: 40px;
        }
    }
    .img_line{
        width: 88px;
        /* margin-bottom: 27px; */
        margin-bottom: 57px;
        @media (max-width: 549px) {
            width: 66px;
            /* margin-bottom: 27px; */
            margin-bottom: 47px;
        }
    }
    .btn_getplan{
        font-weight: 400;
        width: 150px;
        height: 50px;
        font-size: 16px;
        line-height: 28px;
        padding: 11px 16px;
        color: #fff;
        border-color: #5d96ed;
        background-color: #5d96ed;
        border-radius: 3px;
        &:hover{
            border-color: #5d96ed;
            color: #5d96ed;
            background-color: #fff;
        }     
    }
    .plan_con{
        cursor: pointer;
        &:hover{
            scale: 1.03;
            transition: 0.8s;
            box-shadow: 0 0 30px 0 rgba(159,159,159,0.5);
        }
        width: 960px;
        box-shadow: 0 0 5px 0 rgba(159,159,159,0.5);
        border-radius: 10px;
        margin-bottom: 54px;
        position: relative;
        padding-left: 105px;
        padding-right: 105px;
        padding-top: 65px;
        background-color: #fff;
        height: fit-content;
        padding-bottom: 45px;
        @media (max-width: 549px) {
            max-width: 100%;
            padding: 50px 20px;
        }
        h3{
            position: absolute;
            top: -3px;
            left: 72px;
            font-size: 20px;
            font-weight: 400;
            color: #fff;
            line-height: 28px;
            color: rgb(248, 230, 184);
            @media (max-width: 549px) {
                font-size: 18px;
                top: -5px;
                left: 32px;
            }
        }
        h2{
            font-size: 23px;
            font-weight: 400;
            color: #4C4C4C;
            line-height: 50px;
            margin-top: 0;
            margin-bottom: 20px;
            @media (max-width: 549px) {
                font-size: 21px;
                margin-bottom: 10px;
                font-size: 20px;
            }
        }
        h5{
            margin-top: 25px;
            margin-bottom: 5px;
            font-size: 16px;
            color: #4C4C4C;
            font-weight: 400;
            line-height: 21px;
            @media (max-width: 549px) {
                font-size: 15px;
                margin-top: 15px;
            }
        }
        p{
            line-height: 27px;
            font-size: 14px;
            font-weight: 400;
            color: #4C4C4C;
            margin-bottom: 0;
        }
        .img_cover{
            width: 235px;
            height: 57px;
            position: absolute;
            top: -15px;
            left: 25px;
            @media (max-width: 549px) {
                width: 186px;
                height: 47px;
                top: -15px;
                left: 2px;
            }
        }
        .img_corner{
            height: 174px;
            position: absolute;
            bottom: 0;
            right: 0;
            @media (max-width: 549px) {
                height: 124px;
            }
        }
        .file_type {
            position: absolute;
            right: 30px;
            top: 20px;
            font-size: 40px;
            color:  #ce3636;
            @media (max-width: 549px) {
                font-size: 25px;
                right: 15px;
            }
        }
        .img_corner_mobile{
            display: none;
        }
        .img_greyline_plan{
            display: none;
        }
        .has_bought{
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            color: #ce3636;
            margin-right: 6px;
            margin-bottom: 6px;
            background-color: #fce5e5;
            padding: 6px 12px;
            border-radius: 4px;
            position: absolute;
            right: 20px;
            bottom: 10px;
            @media (max-width: 549px) {
                right: 10px;
            }
        }
        div {
            margin-top: 10px;
        }
        .docs_price_now{
            font-size: 30px;
            margin-right: 20px;
            color:#ce3636;
            font-weight: 400;
            @media (max-width: 549px) {
                font-size: 25px;
                margin-right: 15px;
            }
        }
        .docs_price_original{
            font-size: 20px;
            color: #979797;
            text-decoration: line-through;
            @media (max-width: 549px) {
                font-size: 15px;
            }
        }
    }
}
@primary-color: #701919;