html {
    scroll-behavior: smooth;
}
.homePage_wrapper_ca{
    // width: 100%;
    // background: rgb(253, 232, 250);
    background: #fff;
    height: 100vh;
    margin: 0;
    padding: 0;
    .sec-welcome {
        // background-image: linear-gradient(rgb(255, 234, 206),rgba(154, 10, 10, 0.55));
        // background-image: linear-gradient(to right,#B82F25,#ec564bf7);
        
        background-color: #F5F7FB;
        background-size: cover;
        background-position: 50%;
        max-height:980px;
        position: relative;
        width: 100%;
        margin: 60px auto;
        @media (max-width: 549px) {
            max-height: none;
            margin: 50px auto;
        }
        .welcome-container{
            // max-width: 1440px;
            display: flex;
            margin-right: auto;
            margin-left: auto;
            position: relative;
            max-width: 1290px;
            height: 980px;
            padding: 110px 0px;
            @media (max-width: 549px) {
                // height: 320px;
                padding: 20px;
                height: 100%;
                flex-direction: column;
            }
            .left {
                width: 50%;
                @media (max-width: 549px) {
                    width: 100%;
                }
                .topleft-container {
                    align-content: center;
                    align-items: center;
                    margin: 60px 0px 80px 0px;
                    .one{
                        width: auto;
                        .tag {
                            font-size: 12px;
                            padding: 4px 8px;
                            // max-width: 100%;
                            letter-spacing: 2px;
                            font-weight: 600;
                            color: #272727;
                            background-color: #FFE7D530;;
                        }
                    }
                    .two {
                        margin-top: 20px;
                        .des {
                            margin: 0px 120px 0px 0px;
                            font-size: 19px;
                            color: #838383;
                            @media (max-width: 549px) {
                                margin: 0px;
                                font-size: 16px;
                            }
                        }
                    }
                    .three {
                        height: 100px;
                        width: 100%;
                        @media (max-width: 549px) {
                            height: 40px;
                        }
                    }
                    .four {
                        margin: 0px 40px 0px 0px;
                        .text {
                            font-size: 70px;
                            font-weight: 600;
                            line-height: 80px;
                            color: #0C1431;
                            @media (max-width: 549px) {
                                font-size: 50px;
                                line-height: 60px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .five {
                        img {
                            width: 50px;
                            border-style: solid;
                            border-width: 3px 3px 3px 3px;
                            border-color: #FFFFFF;
                            border-radius: 50px 50px 50px 50px;
                            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0,0.2);
                            vertical-align: middle;
                            display: inline-block;
                            margin: 0px 0px 0px -10px;
                            @media (max-width: 549px) {
                                margin: 0px;
                            }
                        }
                        .text {
                            line-height: 100px;
                            display: inline-block;
                            font-size: 65px;
                            font-weight: 600;
                            margin: 0px 40px 0px 20px;
                            vertical-align: middle;
                            @media (max-width: 549px) {
                                font-size: 50px;
                                line-height: 60px;
                                margin: 0px;
                                margin-top: 10px;
                            }
                        }
                    }
                    .six {
                        .btn_explore{
                            width: 200px;
                            height: 54px;
                            font-size: 15px;
                            line-height: 21px;
                            padding: 8px 24px;
                            color: #fff;
                            border-color: #cb4747;
                            background-color: #cb4747;
                            border-radius: 3px;
                            outline: none;
                            margin-right: 30px;
                            margin-top: 28px;
                            transition: 0.5s;
                            text-align: center;
                            letter-spacing: 2px;
                            cursor: pointer;
                            &:hover {
                                color: #fff;
                                background-color: #0C1431;
                                border-color: #0C1431;
                                margin-left: 20px;
                            }
                        }
                        .btn_explore_cn {
                            width: 200px;
                            height: 54px;
                            font-size: 16px;
                            line-height: 21px;
                            padding: 8px 24px;
                            color: #fff;
                            border-color: #cb4747;
                            background-color: #cb4747;
                            border-radius: 3px;
                            outline: none;
                            margin-right: 30px;
                            margin-top: 28px;
                            transition: 0.5s;
                            text-align: center;
                            letter-spacing: 2px;
                            cursor: pointer;
                            &:hover {
                                color: #fff;
                                background-color: #0C1431;
                                border-color: #0C1431;
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }
            .right {
                width: 50%;
                @media (max-width: 549px) {
                    width: 100%;
                }
                .topright-container {
                    align-content: center;
                    align-items: center;
                    background-color: transparent;
                    // background-image: linear-gradient(180deg, #FFC69C 0%, #6CC1BC00 100%);
                    background-image: linear-gradient(180deg, #ff9c9c 0%, #6CC1BC00 100%);
                    border-radius: 10px 10px 10px 10px;
                    .flight_container{
                        right: -25px;
                        max-width: 200px;
                        --container-widget-width: 230px;
                        --container-widget-flex-grow: 0;
                        top: 145px;
                        z-index: 0;
                        margin-bottom: 0;
                        position: absolute;
                        img {
                            vertical-align: middle;
                            display: inline-block;
                            height: auto;
                            max-width: 100%;
                            border: none;
                            border-radius: 0;
                            box-shadow: none;
                            opacity: 0.8;
                        }
                    }
                    
                    .ing_container {
                        width: 100%;
                        margin: 0px 0px 0px 10px;
                        @media (max-width: 549px) {
                            margin-left: 0px;
                        }
                        img {
                            height: auto;
                            max-width: 100%;
                            border: none;
                            border-radius: 0;
                            box-shadow: none;
                            vertical-align: middle;
                            display: inline-block;
                        }
                    }
                    .bottom_container {
                        position: absolute;
                        width: 50%;
                        background-color: transparent;
                        background-image: linear-gradient(180deg, #FFFFFF00 0%, #F5F7FB 80%);
                        right: 0px;
                        bottom: 20px;
                        height: 350px;
                        @media (max-width: 549px) {
                            width: 100%;
                            height: 150px;
                            bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    .sec-trust {
        background-color: #FFFFFF;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        z-index: 5;
        position: relative;
        margin-bottom: 140px;
        @media (max-width: 549px) {
            margin-bottom: 30px;
        }
        .trust-container {
            max-width: 1290px;
            display: flex;
            margin-right: auto;
            margin-left: auto;
            position: relative;
            @media (max-width: 549px) {
                flex-direction: column;
            }
            .left {
                width: 30%;
                display: flex;
                position: relative;
                flex-wrap: wrap;
                align-content: flex-start;
                background-color: #080F28;
                border-radius: 10px 10px 10px 10px;
                margin-top: -100px;
                margin-bottom: 0px;
                padding: 40px 40px 40px 40px;
                word-spacing: 3px;
                z-index: 6;
                height: 100%;
                @media (max-width: 549px) {
                    width: 90%;
                    margin: 0 auto;
                    padding: 25px;
                }
                .one {
                    text-align: left;
                    margin-bottom: 20px;
                    color: #fff;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 35px;
                    @media (max-width: 549px) {
                        font-size: 22px;
                    }
                }
                .two {
                    color: #FFFFFF8A; 
                    font-size: 16px;
                    word-spacing: 0px;
                    @media (max-width: 549px) {
                        font-size: 15px;
                    }
                    img {
                        // width: 100%;
                        padding-left: 40px;
                    }
                }
            }
            .right {
                width: 70%;
                align-content: center;
                align-items: center;
                margin-top:  -60px;
                @media (max-width: 549px) {
                    margin: 0 auto;
                    width: 90%;
                    img {
                        width: 100%;
                    }
                }

            }
        }
    }
    .sec-about {
        .intro-container{
            max-width: 1290px;
            display: flex;
            margin-right: auto;
            margin-left: auto;
            position: relative;
            margin-bottom: 200px;
            @media (max-width: 549px) {
                flex-direction: column-reverse;
                margin-bottom: 30px;
            }
            .left {
                width: 50%;
                @media (max-width: 549px) {
                    width: 100%;
                    padding: 30px;
                }
                .topright-container {
                    align-content: center;
                    align-items: center;
                    background-color: transparent;
                    background-image: linear-gradient(180deg, #ff9c9c 0%, #6CC1BC00 100%);
                    border-radius: 10px 10px 10px 10px;
                    position: relative;      
                    max-width: 460px;              
                    .img_container {
                        // max-width: 100%;
                        margin: 20px -100px 0px 10px;
                        margin-top: 20px;
                        // margin-left: 60px;
                        max-width: 500px;
                        @media (max-width: 549px) {
                            margin: 20px -20px 0px 10px;
                        }
                        img {
                            height: auto;
                            border: none;
                            border-radius: 0;
                            box-shadow: none;
                            vertical-align: middle;
                            display: inline-block;
                            width: 100%
                        }
                    }
                    .bottom_container {
                        position: absolute;
                        width: 100%;
                        background-color: transparent;
                        background-image: linear-gradient(180deg, #FFFFFF00 0%, #fcfdff 80%);
                        left: 0px;
                        bottom: 0px;
                        height: 125px;
                    }
                }
            }
            .right {
                width: 50%;
                align-content: center;
                align-items: center;
                @media (max-width: 549px) {
                    width: 100%;
                    padding: 20px;
                }
                .tag {
                    font-size: 12px;
                    padding: 4px 8px;
                    // max-width: 100%;
                    letter-spacing: 2px;
                    font-weight: 600;
                    color: #272727;
                    background-color: #FFE7D530;
                    margin-bottom: 20px;
                }
                .title {
                    color: #0C1431;
                    font-size: 56px;
                    font-weight: 600;
                    line-height: 1.2em;
                    letter-spacing: 0px;
                    margin-bottom: 20px;
                    @media (max-width: 549px) {
                        font-size: 35px;
                        font-weight: 500;
                    }
                }
                .desc {
                    font-size:  16px;
                    font-weight: 400;
                    line-height: 1.5em;
                    color: #858585;
                    @media (max-width: 549px) {
                        font-size:  15px;
                    }
                }
                .mid-container {
                    margin-top: 40px;
                    margin-bottom: 0px;
                    width: 100%;
                    max-width: 1140px;
                    display: flex;
                    margin-right: auto;
                    margin-left: auto;
                    position: relative;
                    @media (max-width: 549px) {
                        flex-direction: column;
                    }
                    .left {
                        width: 50%;
                        align-content: center;
                        align-items: center;
                        margin: 0px 20px 0px 0px;
                        // display: flex;
                        @media (max-width: 549px) {
                            width: 100%;
                            margin: 0px;
                        }
                        .title {
                            display: flex;
                            margin-bottom: 0px;
                            letter-spacing: 0px;
                            word-spacing: 0px;
                            .icon {
                                border-radius: 50px;
                                margin: 0px 0px 0px 0px;
                                padding: 9px;
                                box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
                                font-size: 19px;
                                color: #ff9c9c;
                                align-self: center;
                                @media (max-width: 549px) {
                                    font-size:  17px;
                                    padding: 6px;
                                }
                            }
                            .text {
                                width: auto;
                                max-width: 100%;
                                font-size: 21px;
                                margin: 0px 0px 0px 20px;
                                letter-spacing: 0px;
                                font-weight: 500;
                                word-spacing: 0px;
                                @media (max-width: 549px) {
                                    font-size:  18px;
                                }
                            }
                        }
                        .title-desc {
                            margin: 0px 0px 15px 0px;
                            font-size:  16px;
                            font-weight: 400;
                            line-height: 1.5em;
                            color: #858585;
                            @media (max-width: 549px) {
                                font-size:  15px;
                            }
                        }
                    }
                    .right {
                        width: 50%;
                        @media (max-width: 549px) {
                            width: 100%;
                        }
                        .img {
                            width: 100%;
                            align-content: center;
                            align-items: center;
                            border-radius: 10px;
                            @media (max-width: 549px) {
                                margin: 0 auto;
                                display: block;
                                width: 85%;
                            }
                        }
                    }
                }
                .divider{
                    width: 100%;
                    margin: 40px 0px 20px 0px;
                    border-style: solid;
                    border-color: #f5f5f5;
                    border-width: 1px;
                    // padding-top: 2px;
                    // padding-bottom: 2px;
                    @media (max-width: 549px) {
                        margin: 10px 0px 5px 0px;
                    }
                }
                .btm-container{
                    display: flex;
                    .left {
                        width: 35%;
                        align-content: center;
                        align-items: center;
                        border-right: 1px solid #e7e7e7;
                        @media (max-width: 549px) {
                            display: none;
                        }
                        .btn_explore{
                            width: 180px;
                            height: 48px;
                            font-size: 13px;
                            line-height: 13px;
                            padding: 6px 22px;
                            color: #fff;
                            border-color: #cb4747;
                            background-color: #cb4747;
                            border-radius: 6px;
                            outline: none;
                            transition: 0.5s;
                            text-align: center;
                            letter-spacing: 2px;
                            cursor: pointer;
                            font-weight: 500;
                            &:hover {
                                color: #fff;
                                background-color: #0C1431;
                                border-color: #0C1431;
                                margin-left: 20px;
                            }
                        }
                        .btn_explore_cn{
                            width: 180px;
                            height: 48px;
                            font-size: 16px;
                            line-height: 13px;
                            padding: 6px 22px;
                            color: #fff;
                            border-color: #cb4747;
                            background-color: #cb4747;
                            border-radius: 6px;
                            outline: none;
                            transition: 0.5s;
                            text-align: center;
                            letter-spacing: 2px;
                            cursor: pointer;
                            font-weight: 500;
                            &:hover {
                                color: #fff;
                                background-color: #0C1431;
                                border-color: #0C1431;
                                margin-left: 20px;
                            }
                        }
                    }
                    .right {
                        width: 65%;
                        align-content: center;
                        align-items: center;
                        margin-left: 30px;
                        @media (max-width: 549px) {
                            width: 100%;
                            margin: 0px auto;
                        }
                        .contact {
                            display: flex;
                            img {
                                // width: 100%;
                                border-radius: 100%;
                                width: 60px;
                                height: 60px;
                                @media (max-width: 549px) {
                                    width: 50px;
                                    height: 50px;
                                    margin-left: 20px;
                                }
                            }
                            .text {
                                text-align: center;
                                margin-left: 20px;
                                @media (max-width: 549px) {
                                    margin-left: 40px;
                                }
                                p {
                                    font-size: 16px;
                                    font-weight: 500;
                                    margin: 0;
                                    @media (max-width: 549px) {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }

    }
    .sec-category {
        .category-container {
            max-width: 1290px;
            margin-right: auto;
            margin-left: auto;
            position: relative;
            margin-bottom: 200px;
            text-align: center;
            @media (max-width: 549px) {
                margin-bottom: 30px;
            }
            .tag {
                // display: block;
                font-size: 12px;
                padding: 4px 8px;
                // max-width: 100%;
                letter-spacing: 2px;
                font-weight: 600;
                color: #272727;
                background-color: #FFE7D530;
                margin-bottom: 20px;
                margin: 0 auto;
            }
            .title {
                margin: 30px auto 60px;
                color: #0C1431;
                font-size: 56px;
                font-weight: 600;
                line-height: 1.2em;
                @media (max-width: 549px) {
                    font-size: 35px;
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
            .list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @media (max-width: 549px) {
                    // flex-direction: column;
                }
                .card {
                    width: 405px;
                    height: 520px;
                    // border: 1px solid #080F28;
                    margin-bottom: 35px;
                    border-radius: 10px;
                    position: relative;
                    @media (max-width: 549px) {
                        width: 85%;
                        height: 390px;
                        margin: 0 auto;
                        margin-bottom: 35px;
                    }
                    .overlay{
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        background-color: transparent;
                        background-image: linear-gradient(180deg, #122C6C17 0%, #0C1431 90%);
                        opacity: 0.75;
                        border-radius: 10px;
                        z-index: 3;
                        &:hover {
                            opacity: 0;
                        }
                    }
                    .desc {
                        text-align: left;
                        padding: 10px 30px;
                        z-index: 4;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        h3 {
                            color: #fff;
                            font-size: 24px;
                            font-weight: 600;
                            @media (max-width: 549px) {
                                font-size: 21px;
                            }
                        }
                        p {
                            font-size: 16px;
                            color: #FFFFFFB3;
                            @media (max-width: 549px) {
                                font-size: 15px;
                            }
                        }
                    }
                }
                .student {
                    background-image: url("../../asset/img/studentvisa.jpg");
                    background-position: 72% 0px;
                    background-size: cover;
                }
                .work {
                    background-image: url("../../asset/img/workvisa.jpg");
                    background-position: 70%;
                    background-size: cover;
                }
                .tourist {
                    background-image: url("../../asset/img/touristvisa.jpg");
                    background-size: cover;
                    background-position: 92%;
                }
                .buiness {
                    background-image: url("../../asset/img/businessvisa.jpg");
                    background-size: cover;
                    background-position: 70%;
                }
                .family {
                    background-image: url("../../asset/img/familyvisa.jpg");
                    background-position: 35%;
                    background-size: cover;
                }
                .super {
                    background-image: url("../../asset/img/supervisa.jpg");
                    background-position: 15%;
                    background-size: cover;
                }
            }
        }
    }
    .sec-success{
        background-color: #F5F7FB;
        padding: 140px 0px 0px 0px;
        position: relative;
        margin-bottom: 600px;
        @media (max-width: 549px) {
            padding: 40px 0px 0px 0px;
            margin-bottom: 330px;
        }
        .overlay{
            background-image: url("https://templatekit.jegtheme.com/vizago/wp-content/uploads/sites/401/2023/08/abstract-background.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            min-height: 550px;
        }
        .success-container {
            max-width: 1290px;
            margin-right: auto;
            margin-left: auto;
            position: relative;
            .top {
                margin-top: 0px;
                margin-bottom: 100px;
                width: 100%;
                display: flex;
                justify-content: space-around;
                @media (max-width: 549px) {
                    flex-wrap: wrap;
                    margin-bottom: 40px;
                }
                .content {
                    width: 25%;
                    text-align: center;
                    border-right: 1px solid #e7e7e7;
                    position: relative;
                    @media (max-width: 549px) {
                        width: 50%;
                        padding-bottom: 10px;
                        &:nth-child(1) {
                            border-bottom: 1px solid #e7e7e7;
                        }
                        &:nth-child(2) {
                            border-bottom: 1px solid #e7e7e7;
                        }
                        &:nth-child(3) {
                            padding-top: 10px;
                        }
                        &:nth-child(4) {
                            padding-top: 10px;
                        }
                    }
                    &:last-child {
                        border-right: none;
                    }
                    .prefix {
                        font-size: 40px;
                        font-weight: 700;
                        color: #0c1843;
                        line-height: 1.2em;
                    }
                    .suffix {
                        color: #ff984c;
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 1.2em;
                        vertical-align: super;
                    }
                    .desc {
                        margin-top: 15px;
                        color: #0312188A;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.5em;
                        @media (max-width: 549px) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            .bottom {
                max-width: 1290px;
                display: flex;
                margin-right: auto;
                margin-left: auto;
                position: absolute;
                height: 370px;
                width: 100%;
                top: 150px;
                @media (max-width: 549px) {
                    position: relative;
                    display: block;
                    top: 0px;
                }
                .left {
                    width: 35%;
                    background-image: url("https://templatekit.jegtheme.com/vizago/wp-content/uploads/sites/401/2023/08/young-couple-at-the-airport-with-flight-tickets-and-passports-e1692682064295.jpg");
                    background-position: 44% 4%;
                    background-size: cover;
                    border-radius: 10px 0px 0px 10px;
                    @media (max-width: 549px) {
                        width: 90%;
                        height: 300px;
                        margin: 0 auto;
                        border-radius: 10px 10px 0px 0px;
                    }
                }
                .right {
                    bottom: -20px;
                    width: 65%;
                    background-color: #0F1735;
                    border-radius: 0px 10px 10px 0px;
                    align-content: center;
                    align-items: center;
                    padding-left: 80px;
                    padding-right: 80px;
                    @media (max-width: 549px) {
                        width: 90%;
                        margin: 0 auto;
                        border-radius: 0px 0px 10px 10px;
                        padding: 30px;
                    }
                    .title {
                        font-size: 40px;
                        color: #fff;
                        line-height: 1.2em;
                        font-weight: 600;
                        max-width: 550px;
                        margin-bottom: 20px;
                        @media (max-width: 549px) {
                            font-size: 25px;
                        }
                    }
                    .desc {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.5em;
                        color: #FFFFFFB3;
                        margin-bottom: 20px;
                        @media (max-width: 549px) {
                            font-size: 14px;
                        }
                    }
                    .btn_explore{
                        margin-top: 20px;
                        width: 225px;
                        height: 55px;
                        font-size: 14px;
                        line-height: 1em;
                        color: #fff;
                        border-color: #cb4747;
                        background-color: #cb4747;
                        outline: none;
                        transition: 0.5s;
                        text-align: center;
                        letter-spacing: 2px;
                        cursor: pointer;
                        font-weight: 600;
                        border-radius: 5px 5px 5px 5px;
                        padding: 20px 40px 20px 40px;
                        &:hover {
                            color: #fff;
                            background-color: #0C1431;
                            border-color: #0C1431;
                            margin-left: 20px;
                        }
                        @media (max-width: 549px) {
                            width: 100%;
                        }
                    }
                    .btn_explore_cn {
                        margin-top: 20px;
                        width: 225px;
                        height: 55px;
                        font-size: 16px;
                        line-height: 1em;
                        color: #fff;
                        border-color: #cb4747;
                        background-color: #cb4747;
                        outline: none;
                        transition: 0.5s;
                        text-align: center;
                        letter-spacing: 2px;
                        cursor: pointer;
                        font-weight: 600;
                        border-radius: 5px 5px 5px 5px;
                        padding: 20px 40px 20px 40px;
                        &:hover {
                            color: #fff;
                            background-color: #0C1431;
                            border-color: #0C1431;
                            margin-left: 20px;
                        }
                        @media (max-width: 549px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .sec-review {
        padding: 140px 0px 120px 0px;
        background-color: #080F28;
        @media (max-width: 549px) {
            padding: 40px 25px;
            padding-bottom: 60px;
        }
        .review-container {
            margin-right: auto;
            margin-left: auto;
            position: relative;
            max-width: 1310px;
            text-align: center;
            justify-content: center;
            .tag {
                // display: block;
                font-size: 12px;
                padding: 4px 8px;
                letter-spacing: 2px;
                font-weight: 600;
                color: #fff;
                background-color: #080F28;
                margin-bottom: 20px;
                margin: 0 auto;
                line-height: 1.2em;
            }
            .title {
                margin: 30px auto;
                color: #fff;
                font-size: 56px;
                font-weight: 600;
                line-height: 1.2em;
                @media (max-width: 549px) {
                    font-size: 35px;
                }
            }
            .desc {
                color: #ffffff8A;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5em;
                max-width: 524px;
                margin: auto;
                margin-bottom: 60px;
                @media (max-width: 549px) {
                    font-size: 15px;
                    margin-bottom: 40px;
                }
            }
            .list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .card {
                    text-align: start;
                    width: 400px;
                    height: 300px;
                    // border: 1px solid #080F28;
                    margin-bottom: 35px;
                    border-radius: 10px;
                    position: relative;
                    background-image: linear-gradient(180deg, #1B223B 0%, #F2295B00 100%);
                    padding: 40px;
                    @media (max-width: 549px) {
                        height: 270px;
                    }
                    .top {
                        margin-top: 0;
                        margin-bottom: .9rem;
                        font-size: 16px;
                        line-height: 1.5em;
                        font-weight: 400;
                        color: #ffffff8A;
                        @media (max-width: 549px) {
                            font-size: 14px;
                        }
                    }
                    .rate {
                        font-size: 16px;
                        margin-bottom: 24px;
                        @media (max-width: 549px) {
                            font-size: 14px;
                        }
                    }
                    .bottom {
                        display: flex;
                        img {
                            // width: 100%;
                            border-radius: 100%;
                            width: 58px;
                            height: 58px;
                            margin-right: 5px;
                            @media (max-width: 549px) {
                                width: 50px;
                                height: 50px;
                            }
                        }
                        .text {
                            // text-align: center;
                            margin-left: 20px;
                            align-items: center;
                            align-self: center;
                            p {
                                font-size: 16px;
                                font-weight: 500;
                                margin: 0;
                                color: #fff;
                                @media (max-width: 549px) {
                                    font-size: 15px;
                                }
                            }
                            p:nth-child(2) {
                                color: #ffffff8A;
                            }
                        }
                    }
                }
            }
        }
    }
}
  
@primary-color: #701919;