.pkg_resume {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.pkg_resume .pcontent {
  padding: 0 20px 0;
  max-width: 1170px;
  margin: auto;
}
@media (max-width: 549px) {
  .pkg_resume .pcontent {
    padding: 0 25px;
 }
}
.pkg_resume .btn_floating {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 180px;
  height: 186px;
  border-radius: 5px;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 999;
  top: 150px;
  background-color: rgba(255,255,255,0.6);
}
.pkg_resume .btn_floating:hover {
  background-color: #ffffff;
}
.pkg_resume .btn_floating h4 {
  font-size: 14px;
  line-height: 20px;
  color: #585858;
  font-weight: 400;
  margin-top: 0;
  text-align: center;
}
.pkg_resume .img_wechat_floating {
  width: 130px;
  display: block;
  margin: auto;
}
.pkg_resume .top_nav {
  margin-top: 72px;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.pkg_resume .top_nav .top_ul_wrap {
  // position: fixed;
  width: 100%;
  left: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  background-color: #F9F9F9;
  z-index: 20;
  transition: transform .5s;
  transform: translateZ(0);
}
.pkg_resume .top_nav .top_ul {
  max-width: 1130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.pkg_resume .quanfangwei{
  margin: 10px auto;
  max-width: 980px;
  img {
      max-width: 980px;
      @media (max-width:768px) {
          width: 100%;
          margin: -15px 0px 10px;
      }
  }
}

@media (max-width: 767px) {
  .pkg_resume .top_nav .top_ul {
    padding: 0px 5px;
 }
  .pkg_resume .top_nav .top_ul li {
    padding: 0px 10px;
 }
}
@media (max-width: 360px) {
  .pkg_resume .top_nav .top_ul {
    padding: 0px 5px;
 }
  .pkg_resume .top_nav .top_ul li {
    padding: 0px 0px;
 }
}
.pkg_resume .top_nav .top_ul a {
  height: 55px;
}
.pkg_resume .top_nav .active {
  background-image: url('../../asset/img/offerplus/Line.png');
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 55px;
  background-position-y: bottom;
}
.pkg_resume .top_nav .active .plan_en {
  font-weight: 600;
  color: #727272;
}
.pkg_resume .top_nav .active .plan_ch {
  font-weight: 700;
  color: #3C3C3C;
}
.pkg_resume .top_nav .plan_en {
  font-weight: 400;
  margin-top: 18px;
  font-size: 16px;
  line-height: 22px;
  color: #727272;
}
.pkg_resume .top_nav .plan_ch {
  font-size: 16px;
  color: #727272;
  line-height: 22px;
  font-weight: 400;
  margin-top: 18px;
}
.pkg_resume .top_nav .plus_plan {
  display: flex;
}
.pkg_resume .top_nav .plus_plan .img_bplus {
  display: none;
}
.pkg_resume .top_nav .plus_plan .img_gplus {
  height: 13px;
  margin-left: 2px;
  margin-right: 3px;
  margin-top: 22px;
}
.pkg_resume .top_nav li:hover {
  background-image: url('../../asset/img/offerplus/Line.png');
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 55px;
  background-position-y: bottom;
}
.pkg_resume .top_nav li:hover .plan_en {
  font-weight: 600;
}
.pkg_resume .top_nav li:hover .plan_ch {
  font-weight: 600;
}
.pkg_resume .header_resume {
  margin-top: 72px;
  height: 602px;
  background-image: linear-gradient(to bottom left,#bdd7ff,#ff6557);
  // background-image: red;
}
.pkg_resume .header_resume .header_content {
  position: relative;
  height: 100%;
  max-width: 1024px;
  margin: auto;
  padding-left: 25px;
  padding-right: 25px;
}
.pkg_resume .header_resume .background_img {
  height: 585px;
  position: absolute;
  bottom: 10px;
  right: -174px;
}
.pkg_resume .header_resume .backgound_img_mobile {
  display: none;
}
.pkg_resume .header_resume .header_des {
  width: 400px;
  position: absolute;
  left: 35px;
  top: 180px;
}
.pkg_resume .header_resume .header_des h5 {
  font-size: 40px;
  line-height: 47px;
  font-weight: 700;
  margin-bottom: 14px;
  color: #fff;
  height: 56px;
}
.pkg_resume .header_resume .header_des p {
  margin-bottom: 0;
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  height: 50px;
}
.pkg_resume .header_resume .btn_consult {
  width: 121px;
  height: 42px;
  font-size: 18px;
  line-height: 21px;
  padding: 8px 24px;
  color: #fff;
  border-color: #cb4747;
  background-color: #cb4747;
  border-radius: 3px;
  outline: none;
  margin-right: 30px;
  margin-top: 28px;
  transition: 0.5s;
  text-align: center;
}
.pkg_resume .header_resume .btn_consult:hover {
  color: #d07979;
  background-color: #fff;
  border-color: #fff;
}
.pkg_resume .package_details {
  max-width: 1130px;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(198,198,198,0.5);
  margin: auto;
  margin-top: -25px;
  padding: 30px 74px 33px;
  position: relative;
  background-color: #fff;
}
.pkg_resume .package_details .details_content {
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.pkg_resume .package_details .details_content .package_detail {
  width: 290px;
  padding-left: 20px;
}
@media (max-width: 549px) {
  .pkg_resume .package_details .details_content .package_detail {
    width: 100%;
    padding: 0;
 }
}
.pkg_resume .package_details .details_content .package_detail img {
  vertical-align: sub;
  width: 18px;
}
.pkg_resume .package_details .details_content .package_detail .detail_title {
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin-left: 7px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
}
.pkg_resume .package_details .details_content .package_detail .detail_des {
  font-size: 14px;
  line-height: 24px;
  color: #646464;
  width: 250px;
}
@media (max-width: 549px) {
  .pkg_resume .package_details .details_content .package_detail .detail_des {
    width: 100%;
 }
}
.pkg_resume .advantage {
  display: flex;
  align-items: center;
}
.pkg_resume .advantage img {
  margin-right: 18px;
  width: 55px;
  // width:75px;
}
.pkg_resume .advantage h5 {
  color: #8b3434;
  font-size: 22px;
  line-height: 26px;
  margin: 0;
  font-weight: 400;
}
.pkg_resume .advantage p {
  font-size: 15px;
  color: #B4B4B4;
  line-height: 18px;
  margin: 5px 0 0;
}
.pkg_resume .diagnoseContent {
  max-width: 1024px;
  margin: auto;
  background: #fff;
  margin-top: 77px;
}
.pkg_resume .diagnoseContent .rusume_des {
  max-width: 1024px;
  margin: auto;
  padding: 40px 80px;
  box-shadow: 0px 0px 8px rgba(166,166,166,0.5);
  border-radius: 8px;
  margin-top: 77px;
}
.pkg_resume .diagnoseContent .rusume_des .first_title {
  font-size: 24px;
  margin-bottom: 0;
}
.pkg_resume .diagnoseContent .rusume_des .des_line {
  margin-top: 14px;
  height: 2px;
  width: 51px;
}
.pkg_resume .diagnoseContent .rusume_des .description {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.pkg_resume .diagnoseContent .rusume_des .fact {
  width: 210px;
}
.pkg_resume .diagnoseContent .rusume_des img {
  height: 90px;
  width: 90px;
  margin-right: 15px;
}
.pkg_resume .diagnoseContent .rusume_des .number {
  display: inline;
  font-size: 45px;
  font-weight: 800;
  color: #334481;
  vertical-align: bottom;
}
.pkg_resume .diagnoseContent .rusume_des .unit {
  display: inline;
  font-size: 20px;
  font-weight: 400;
  margin-left: 5px;
  vertical-align: bottom;
  color: #334481;
}

.pkg_resume .diagnoseContent .rusume_des .number_3 {
  display: inline;
  font-size: 45px;
  font-weight: 800;
  color: #991B1D;
  vertical-align: bottom;
  @media (max-width: 767px) {
    vertical-align:text-bottom !important;
    font-size: 30px;
  }
}
.pkg_resume .diagnoseContent .rusume_des .unit_3 {
  color: #991B1D;
  display: inline;
  font-size: 20px;
  font-weight: 400;
  margin-left: 5px;
  vertical-align: bottom;
  @media (max-width: 767px) {
    vertical-align:text-bottom !important;
    font-size: 16px;
  }
}

.pkg_resume .diagnoseContent .rusume_des p {
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  font-weight: 400;
  color: #1a1919;
  line-height: 28px;
  margin-top: 25px;
}
.pkg_resume .diagnoseContent .desContent {
  max-width: 1024px;
  margin: 66px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pkg_resume .diagnoseContent .desContent img {
  width: 130px;
  height: 120px;
}
.pkg_resume .diagnoseContent .desContent p {
  margin-left: 50px;
  font-size: 16px;
  color: #898989;
  line-height: 35px;
  margin-bottom: 0;
}
.pkg_resume .diagnoseContent .desContentBottom {
  width: 1024px;
  font-weight: 400;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 35px;
  margin: 0 auto;
  margin-top: 53px;
}
.pkg_resume .diagnoseContent .aboutResume {
  margin-top: 77px;
}
@media (max-width: 1023px) {
  .pkg_resume .diagnoseContent .aboutResume .advantage {
    padding: 0 30px;
 }
}
.pkg_resume .diagnoseContent .aboutResume .advantage img {
  width: 70px;
  height: 55px;
  margin-right: 20px;
}
.pkg_resume .diagnoseContent .aboutResume .advantage h5 {
  width: 132px;
  height: 31px;
  font-size: 22px;
  line-height: 26px;
  color: #8b3434;
}
.pkg_resume .diagnoseContent .aboutResume .advantage p {
  font-size: 15px;
  line-height: 18px;
  color: #B4B4B4;
  width: 260px;
  height: 21px;
}
.pkg_resume .diagnoseContent .highlight {
  max-width: 1024px;
  margin: auto;
  background: #fff;
  margin-top: 70px;
}
.pkg_resume .diagnoseContent .highlight .advantage img {
  width: 70px;
  height: 55px;
  margin-right: 20px;
}
.pkg_resume .diagnoseContent .highlight .advantage h5 {
  height: 31px;
  font-size: 22px;
  line-height: 26px;
  color: #8b3434;
}
.pkg_resume .diagnoseContent .highlight .advantage p {
  font-size: 15px;
  line-height: 18px;
  color: #B4B4B4;
}
.pkg_resume .diagnoseContent .highlight .itemContain {
  background: color;
  padding: 50px 50px 0 70px;
}
.pkg_resume .diagnoseContent .highlight .itemContain .itemRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item {
  display: flex;
  width: 400px;
  align-items: center;
}
.pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item .des h2 {
  font-weight: 400;
  font-size: 18px;
}
.pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item .des .con {
  width: 100%;
  font-size: 15px;
}
.pkg_resume .ResumeGuide {
  width: 985px;
  margin: auto;
  margin-top: 120px;
}
.pkg_resume .ResumeGuide .flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.pkg_resume .ResumeGuide .l {
  width: 530px;
}
.pkg_resume .ResumeGuide .r, .pkg_resume .ResumeGuide .zhinantu {
  width: 378px;
  height: 247px;
  display: block;
}
.pkg_resume .ResumeGuide .GuideTitle {
  margin-bottom: 40px;
  line-height: 35px;
}
.pkg_resume .ResumeGuide .GuideTitle .tit {
  font-size: 26px;
  font-weight: 400;
  color: #000;
}
.pkg_resume .ResumeGuide .GuideTitle .who_des_line {
  height: 5px;
  margin: 10px 0 30px;
}
.pkg_resume .ResumeGuide .GuideTitle .des {
  font-size: 16px;
  font-weight: 400;
}
.pkg_resume .ResumeGuide .GuideButton {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.pkg_resume .ResumeGuide .GuideButton .button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 159px;
  height: 77px;
  border: 1px solid #687AB2;
  transition: 1s;
  font-size: 14px;
  color: #6D6D6D;
}
.pkg_resume .ResumeGuide .GuideButton .button:hover {
  transform: scale(1.1);
}
.pkg_resume .ResumeGuide .GuideButton .button strong {
  color: #ce3636;
  font-size: 18px;
  font-weight: normal;
}
.pkg_resume .details {
  max-width: 1024px;
  margin: auto;
  background: #fff;
  margin-top: 75px;
}
@media (max-width: 1023px) {
  .pkg_resume .details .advantage {
    padding: 0 30px;
 }
}
.pkg_resume .details .advantage img {
  width: 63px;
  height: 55px;
  margin-right: 20px;
}
.pkg_resume .details .advantage h5 {
  width: 132px;
  height: 31px;
  font-size: 22px;
  line-height: 26px;
  color: #8b3434;
}
.pkg_resume .details .advantage p {
  font-size: 15px;
  line-height: 18px;
  color: #B4B4B4;
  width: 260px;
  height: 21px;
}
.pkg_resume .details .content {
  width: 942px;
  margin: 0 auto;
  margin-top: 60px;
}
.pkg_resume .details .content .table_main {
  width: 100%;
  box-shadow: none;
  position: relative;
}
.pkg_resume .details .content .table_main:after {
  content: '';
  position: absolute;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 2px 4px 0 #999;
  -moz-box-shadow: 0px 2px 4px 0 #999;
  -o-box-shadow: 0px 2px 4px 0 #999;
  -ms-box-shadow: 0px 2px 4px 0 #999;
  border-radius: 8px;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
  z-index: 1;
}
.pkg_resume .details .content .table_main .mobile {
  display: none;
}
.pkg_resume .details .content .table_main tbody {
  padding: 10px 0;
}
.pkg_resume .details .content .table_main td, .pkg_resume .details .content .table_main th {
  width: 103px;
  text-align: center;
  line-height: 50px;
}
@media (max-width: 950px) {
  .pkg_resume .details .content .table_main td, .pkg_resume .details .content .table_main th {
    line-height: 21px;
 }
}
.pkg_resume .details .content .table_main td:first-child, .pkg_resume .details .content .table_main th:first-child {
  width: 165px;
  text-align: left;
  padding: 15px 10px 15px 35px;
  color: #4a4a4a;
  font-size: 16px;
}
.pkg_resume .details .content .table_main tr.empty td {
  padding: 10px 0;
}
.pkg_resume .details .content .table_main th {
  height: 53px;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}
.pkg_resume .details .content .table_main th strong {
  display: block;
  font-weight: bold;
  font-size: 18px;
}
.pkg_resume .details .content .table_main .icon {
  color: #ce3636;
  font-size: 25px;
  font-weight: 600;
}
.pkg_resume .details .content .table_main .imgCheck {
  width: 22px;
}
@media (max-width: 767px) {
  .pkg_resume .details .content .table_main .imgCheck {
    width: 16px;
 }
}
.pkg_resume .details .content .table_main .grey {
  background-color: #fcfbfb;
}
.pkg_resume .details .content .table_main .grey-high {
  background-color: #f3f3f3;
}
.pkg_resume .details .content .table_main .con_second {
  margin-left: 30px;
}
.pkg_resume .details .content .table_main .con_bracket {
  margin-left: -8px;
}
.pkg_resume .details .content .table_main .time {
  font-size: 15px;
  font-weight: 600;
  color: rgba(0,0,0,0.7);
}
.pkg_resume .details .content .table_price {
  width: 100%;
  margin: 50px auto 0;
  box-shadow: 0px 2px 4px 0 #999;
  border-radius: 8px;
}
.pkg_resume .details .content .table_price td {
  width: 103px;
  text-align: center;
}
.pkg_resume .details .content .table_price td:first-child {
  width: 165px;
  padding: 15px 10px 15px 35px;
  text-align: left;
  font-size: 22px;
  color: #ce3636;
  font-weight: 400;
}
.pkg_resume .details .content .table_price .grey {
  background-color: #fcfbfb;
}
.pkg_resume .details .content .table_price .grey-high {
  background-color: #f3f3f3;
}
.pkg_resume .details .content .table_price .regularPrice {
  padding-top: 15px;
  font-size: 20px;
  font-weight: 400;
  text-decoration: line-through;
}
.pkg_resume .details .content .table_price .realPrice {
  font-size: 23px;
  font-weight: 400;
  color: #ce3636;
  padding-bottom: 15px;
}
.pkg_resume .details .content .table_price .priceDollar {
  font-size: 16px;
  color: #999;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .pkg_resume .details .content .table_price .priceDollar {
    display: block;
    font-size: 15px;
 }
}
.pkg_resume .details .content .ukPayDes {
  font-size: 14px;
  color: #666;
  font-weight: normal;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .pkg_resume .details .content .ukPayDes {
    display: none;
 }
}
.pkg_resume .details .content .ukPayDesMobile {
  display: none;
  margin-top: 10px;
  text-align: right;
  font-size: 13px;
}
@media (max-width: 767px) {
  .pkg_resume .details .content .ukPayDesMobile {
    display: block;
 }
}
.pkg_resume .details .content .table_buy {
  width: 100%;
  margin: 20px auto 0;
}
.pkg_resume .details .content .table_buy td {
  width: 103px;
  text-align: center;
}
.pkg_resume .details .content .table_buy td:first-child {
  width: 165px;
  padding: 15px 10px 15px 35px;
  text-align: left;
  font-size: 22px;
  color: #ce3636;
  font-weight: 700;
}
.pkg_resume .details .content .table_buy td .BuyButton {
  color: #fff;
  background-color: #ce3636;
  width: 100px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
}
.pkg_resume .details .content .table_buy td .BuyButton:hover {
  color: #ce3636;
  background-color: #fff;
}
.pkg_resume .flowPath {
  position: relative;
  margin-top: 120px;
}
.pkg_resume .flowPath:after {
  content: '';
  position: absolute;
  background-color: #ffeaea;
  width: 200%;
  height: 325px;
  top: -45px;
  left: -50%;
  z-index: 0;
}
@media (max-width: 549px) {
  .pkg_resume .flowPath {
    margin-top: 100px;
 }
}
.pkg_resume .flowPath .enroll_title {
  font-size: 30px;
  font-weight: 400;
  line-height: 42px;
  color: #000;
  width: 890px;
  margin: auto;
  margin-bottom: 10px;
  text-align: center;
  z-index: 1;
  position: relative;
}
.pkg_resume .flowPath .enroll_des {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #969696;
  width: 890px;
  margin: auto;
  margin-bottom: 0;
  text-align: center;
  z-index: 1;
  position: relative;
}
.pkg_resume .flowPath .enroll_step_con {
  width: 100%;
  max-width: 942px;
  margin: 54px auto 76px;
  text-align: center;
  z-index: 1;
  position: relative;
}
.pkg_resume .flowPath .enroll_step1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: #983333;
  padding: 18px 30px;
  border: 1px solid #ffa8a8;
  box-shadow: 5px 8px 15px 0 rgba(0,0,0,0.16);
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
}
@media (max-width: 549px) {
  .pkg_resume .flowPath .enroll_step1 {
    padding: 18px 20px;
 }
}
.pkg_resume .flowPath .enroll_step2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: #983333;
  padding: 18px 30px;
  border: 1px solid #ffa8a8;
  box-shadow: 5px 8px 15px 0 rgba(0,0,0,0.16);
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
}
.pkg_resume .flowPath .enroll_step3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: #983333;
  padding: 18px 30px;
  border: 1px solid #ffa8a8;
  box-shadow: 5px 8px 15px 0 rgba(0,0,0,0.16);
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
}
.pkg_resume .flowPath .enroll_step4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
  padding: 18px 24px;
  box-shadow: 5px 8px 15px 0 rgba(0,0,0,0.16);
  background-color: #ff5b5b;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}
.pkg_resume .flowPath .enroll_step4 span {
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
}
.pkg_resume .flowPath .img_enroll_step {
  margin: 38px 24px;
}
.pkg_resume .faq {
  width: 100%;
  max-width: 985px;
  margin: 78px auto 100px;
}
.pkg_resume .faq .ant-collapse {
  background-color: #fff;
  margin-top: 60px;
}
.pkg_resume .faq .ant-collapse-item {
  width: 100%;
  border: none;
  padding: 12px 15px;
  margin: 0 0 10px;
  box-shadow: 0 0 2px rgba(0,0,0,0.15);
  border-radius: 2px;
  background: #fff;
  position: relative;
  font-size: 16px;
}
.pkg_resume .faq .ant-collapse-arrow {
  color: #5B8CFF !important;
}
.pkg_resume .faq .ant-collapse-content {
  padding-left: 25px;
}
.pkg_resume .faq_title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.pkg_resume .bottom {
  width: 985px;
  padding: 0;
  margin: 50px auto 70px;
  position: relative;
  height: 350px;
}
.pkg_resume .bottom h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .pkg_resume .advantage p {
    margin: 0;
 }
  .pkg_resume .top_nav {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
    margin-top: 62px;
 }
  .pkg_resume .top_nav .plan_en, .pkg_resume .top_nav .plan_ch {
    font-size: 14px;
    line-height: 20px;
 }
  .pkg_resume .header_resume {
    height: 550px;
 }
  .pkg_resume .header_resume .header_content {
    width: 100%;
 }
  .pkg_resume .header_resume .background_img {
    display: none;
 }
  .pkg_resume .header_resume .backgound_img_mobile {
    display: block;
    height: 210px;
    position: absolute;
    bottom: 30px;
    left: 10;
 }
  .pkg_resume .header_resume .header_des {
    top: 110px;
 }
  .pkg_resume .header_resume .header_des h5 {
    font-size: 30px;
 }
  .pkg_resume .header_resume .header_des p {
    font-size: 17px;
 }
  .pkg_resume .header_resume .btn_consult {
    box-shadow: 0 0 5px 0 #777;
 }
  .pkg_resume .package_details {
    height: auto;
    width: 100%;
    padding: 25px 20px;
 }
  .pkg_resume .package_details .details_content {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
 }
  .pkg_resume .package_details .package_detail {
    width: 100%;
    height: auto;
 }
  .pkg_resume .diagnoseContent {
    width: 100%;
 }
  .pkg_resume .diagnoseContent .rusume_des {
    width: 100%;
    margin-top: 0px;
    border: none;
    box-shadow: none;
    padding-left: 30px;
    margin-bottom: 30px;
 }
  .pkg_resume .diagnoseContent .rusume_des .first_title {
    font-size: 20px;
 }
  .pkg_resume .diagnoseContent .rusume_des .description {
    margin-top: 20px;
    flex-direction: column;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact {
    width: 100%;
    padding-left: 10px;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact img {
    width: 75px;
    height: 75px;
    margin-right: 15px;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact .number {
    display: inline;
    font-size: 30px;
    font-weight: 800;
    vertical-align: top;
    line-height: 26px;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact .unit {
    margin-left: 5px;
    vertical-align: top;
    line-height: 26px;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact p {
    position: relative;
    bottom: 60px;
    left: 90px;
 }
  .pkg_resume .diagnoseContent .desContent {
    width: 100%;
    margin: 0;
    margin-top: -55px;
 }
  .pkg_resume .diagnoseContent .desContent img {
    width: 86px;
    height: 60px;
 }
  .pkg_resume .diagnoseContent .desContent p {
    margin-left: 0px;
    font-size: 15px;
    line-height: 28px;
 }
  .pkg_resume .diagnoseContent .desContentBottom {
    margin-left: 20px;
    font-size: 15px;
    line-height: 29px;
    width: 90%;
 }
  .pkg_resume .diagnoseContent .highlight {
    padding: 0 30px;
 }
  .pkg_resume .diagnoseContent .highlight .advantage img {
    width: 50px;
 }
  .pkg_resume .diagnoseContent .highlight .advantage h5 {
    font-size: 18px;
 }
  .pkg_resume .diagnoseContent .highlight .advantage p {
    font-size: 14px;
    line-height: 22px;
    width: 250px;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain {
    width: 90%;
    padding: 10px;
 }
  .pkg_resume .ResumeGuide {
    width: 100%;
    margin: 130px auto 0px;
    padding: 0 30px;
 }
  .pkg_resume .ResumeGuide .GuideTitle {
    width: 90%;
 }
  .pkg_resume .ResumeGuide .GuideButton {
    width: 90%;
    flex-direction: column;
    justify-content: none;
    height: 235px;
    margin: 10px 0px;
    align-items: flex-start;
 }
  .pkg_resume .ResumeGuide .GuideButton .button {
    margin: 10px 0;
    font-size: 13px;
 }
  .pkg_resume .details {
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
 }
  .pkg_resume .details .advantage {
    padding: 0 15px;
 }
  .pkg_resume .details .advantage img {
    width: 63px;
    height: 50px;
 }
  .pkg_resume .details .advantage h5 {
    font-size: 18px;
 }
  .pkg_resume .details .advantage p {
    font-size: 14px;
    line-height: 22px;
    width: 250px;
 }
  .pkg_resume .details .content {
    width: 90%;
    padding-left: 20px;
    padding-right: 20px;
 }
  .pkg_resume .details .content .table_buy {
    width: 100%;
 }
  .pkg_resume .faq {
    width: 100%;
    margin: 100px auto 50px;
    padding: 0 30px;
 }
  .pkg_resume .bottom {
    width: 100%;
    height: auto;
 }
  .pkg_resume .bottom h2 {
    font-size: 22px;
 }
}
@media (max-width: 1023px) and (max-width: 549px) {
  .pkg_resume .ResumeGuide .GuideButton {
    align-items: center;
 }
}
@media (max-width: 950px) {
  .pkg_resume .diagnoseContent .highlight .itemContain {
    padding: 0;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain .itemRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item {
    padding: 20px 0;
 }
  .pkg_resume .flowPath:after {
    height: calc(200%);
 }
  .pkg_resume .flowPath .enroll_title {
    font-size: 26px;
    width: 100%;
    line-height: 37px;
 }
  .pkg_resume .flowPath .enroll_des {
    display: none;
 }
  .pkg_resume .flowPath .enroll_step_con {
    text-align: center;
    margin: 35px auto 76px;
 }
  .pkg_resume .flowPath .img_enroll_step {
    transform: rotate(90deg);
    display: block;
    margin: 16px auto;
 }
  .pkg_resume .flowPath .enroll_step1 {
    margin: auto;
    display: block;
    width: 155px;
    text-align: center;
 }
  .pkg_resume .flowPath .enroll_step2 {
    margin: auto;
    display: block;
    width: 160px;
    text-align: center;
    padding: 12px;
 }
  .pkg_resume .flowPath .enroll_step3 {
    display: block;
    width: 220px;
    text-align: center;
    margin: auto;
 }
  .pkg_resume .flowPath .enroll_step4 {
    display: block;
    width: 240px;
    text-align: center;
    margin: auto;
 }
  .pkg_resume .ResumeGuide .GuideTitle {
    width: 400px;
 }
  .pkg_resume .ResumeGuide .r {
    margin-left: -185px;
    margin-bottom: 0px;
 }
  .pkg_resume .ResumeGuide .r .zhinantu {
    width: 310px;
    height: 184px;
 }
}
@media (max-width: 549px) {
  .pkg_resume .btn_floating {
    display: none;
 }
  .pkg_resume .header_resume {
    height: 550px;
    background-image: linear-gradient(to bottom left,#bdd7ff,#ff6557);
 }
  .pkg_resume .header_resume .header_content .background_img {
    display: none;
 }
  .pkg_resume .header_resume .header_content .backgound_img_mobile {
    display: block;
    height: 220px;
    position: absolute;
 }
  .pkg_resume .header_resume .header_content .header_des {
    top: 100px;
    width: calc(70vw);
    left: 25px;
 }
  .pkg_resume .header_resume .header_content .header_des h5 {
    font-size: 28px;
 }
  .pkg_resume .header_resume .header_content .header_des p {
    font-size: 16px;
    width: 90%;
    margin-bottom: 20px;
 }
  .pkg_resume .header_resume .header_content .header_des .btn_consult {
    margin-top: 0px;
    box-shadow: 0 0 5px 0 #777;
 }
  .pkg_resume .diagnoseContent {
    width: 100%;
    max-width: initial;
    margin-top: 0px;
 }
  .pkg_resume .diagnoseContent .aboutResume {
    margin-top: 60px;
 }
  .pkg_resume .diagnoseContent .aboutResume .advantage {
    padding: 0;
    align-items: end;
 }
  .pkg_resume .diagnoseContent .aboutResume .advantage img {
    width: 42px;
    height: 39px;
    margin-right: 16px;
    margin-bottom: 14px;
 }
  .pkg_resume .diagnoseContent .aboutResume .advantage h5 {
    width: 100%;
    height: auto;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #8b3434;
 }
  .pkg_resume .diagnoseContent .aboutResume .advantage p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    height: auto;
    color: #B4B4B4;
 }
  .pkg_resume .diagnoseContent .rusume_des {
    width: 100%;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 0;
 }
  .pkg_resume .diagnoseContent .rusume_des .first_title {
    font-size: 20px;
 }
  .pkg_resume .diagnoseContent .rusume_des .des_line {
    margin-top: 0;
    height: 4px;
    width: auto;
 }
  .pkg_resume .diagnoseContent .rusume_des .description {
    margin-top: 40px;
    flex-direction: column;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact {
    width: 100%;
    padding-left: 0px;
    position: relative;
    height: 90px;
    margin-bottom: 35px;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact img {
    width: 75px;
    height: 75px;
    margin-right: 15px;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact .number {
    font-size: 30px;
    line-height: 42px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-bottom: 0;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact .unit {
    font-size: 16px;
    line-height: 42px;
    vertical-align: super;
    display: inline-block;
    margin-bottom: 0;
 }
  .pkg_resume .diagnoseContent .rusume_des .description .fact p {
    font-size: 14px;
    line-height: 28px;
    left: 95px;
    padding: 0;
    bottom: initial;
    top: 40px;
    margin: 0;
    width: auto;
    position: absolute;
 }
  .pkg_resume .diagnoseContent .desContent {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    align-items: flex-end;
 }
  .pkg_resume .diagnoseContent .desContent img {
    width: 85px;
    height: 108px;
    padding: 0 0 30px;
    height: auto;
    margin-right: 10px;
 }
  .pkg_resume .diagnoseContent .desContent p {
    font-size: 14px;
    line-height: 28px;
    padding: 0;
    width: auto;
 }
  .pkg_resume .diagnoseContent .desContentBottom {
    font-size: 16px;
    line-height: 32px;
    padding: 0;
    margin: 25px 0 0;
    width: 100%;
 }
  .pkg_resume .diagnoseContent .highlight {
    padding: 0;
    margin-top: 60px;
 }
  .pkg_resume .diagnoseContent .highlight .advantage {
    align-items: end;
 }
  .pkg_resume .diagnoseContent .highlight .advantage img {
    width: 50px;
    height: 39px;
    margin-right: 16px;
    margin-bottom: 33px;
 }
  .pkg_resume .diagnoseContent .highlight .advantage h5 {
    width: 100%;
    height: auto;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 5px;
 }
  .pkg_resume .diagnoseContent .highlight .advantage p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    height: auto;
    color: #B4B4B4;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain {
    width: 100%;
    padding: 0;
    margin-top: 25px;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item {
    width: 100%;
    padding: 0;
    margin-bottom: 25px;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item img {
    width: 45px;
    height: 45px;
    margin-right: 20px;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item .des h2 {
    font-weight: 600;
    font-size: 16px;
 }
  .pkg_resume .diagnoseContent .highlight .itemContain .itemRow .item .des .con {
    width: 100%;
    font-size: 14px;
 }
  .pkg_resume .ResumeGuide {
    width: 100%;
    margin: 100px auto 20px;
    padding: 0;
 }
  .pkg_resume .ResumeGuide .advantage {
    align-items: flex-end;
 }
  .pkg_resume .ResumeGuide .advantage img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
 }
  .pkg_resume .ResumeGuide .advantage h5 {
    width: 100%;
    height: auto;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 5px;
 }
  .pkg_resume .ResumeGuide .advantage p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    height: auto;
    color: #B4B4B4;
 }
  .pkg_resume .ResumeGuide .flex {
    margin-top: 24px;
    display: block;
    position: relative;
 }
  .pkg_resume .ResumeGuide .flex .GuideTitle {
    margin-bottom: 35px;
    width: 100%;
 }
  .pkg_resume .ResumeGuide .flex .GuideTitle .tit {
    font-size: 20px;
    font-weight: 400;
    color: #000;
 }
  .pkg_resume .ResumeGuide .flex .GuideTitle .who_des_line {
    height: 5px;
    margin: 10px 0 20px;
 }
  .pkg_resume .ResumeGuide .flex .GuideTitle .des {
    font-size: 14px;
    line-height: 30px;
    width: 100%;
 }
  .pkg_resume .ResumeGuide .flex .GuideButton {
    display: block;
    height: auto;
    width: 140px;
    padding-bottom: 25px;
 }
  .pkg_resume .ResumeGuide .flex .GuideButton .button {
    margin: 10px 0px;
    width: 115px;
    height: 55px;
    border: 1px solid #5B8CFC;
    padding: 9.5px 16px;
 }
  .pkg_resume .ResumeGuide .flex .GuideButton .button strong {
    color: #687AB2;
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 2px;
 }
  .pkg_resume .ResumeGuide .flex .GuideButton .button span {
    font-size: 12px;
    line-height: 14px;
 }
  .pkg_resume .ResumeGuide .flex .l {
    width: 100%;
 }
  .pkg_resume .ResumeGuide .flex .r {
    position: absolute;
    right: 0;
    top: 115px;
    margin: 0;
    height: auto;
    width: auto;
 }
  .pkg_resume .ResumeGuide .flex .r .zhinantu {
    margin: 25px 0 0;
    width: 150px;
    height: auto;
 }
  .pkg_resume .details {
    padding: 0;
    margin-top: 35px;
 }
  .pkg_resume .details .advantage {
    padding: 0;
    align-items: end;
 }
  .pkg_resume .details .advantage img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
 }
  .pkg_resume .details .advantage h5 {
    width: 100%;
    height: auto;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 5px;
 }
  .pkg_resume .details .advantage p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    height: auto;
    color: #B4B4B4;
 }
  .pkg_resume .details .content {
    width: 100%;
    padding: 0;
    margin-top: 35px;
 }
  .pkg_resume .details .content .table_main {
    font-size: 14px;
 }
  .pkg_resume .details .content .table_main .normal {
    display: none;
 }
  .pkg_resume .details .content .table_main .mobile {
    display: table-header-group;
 }
  .pkg_resume .details .content .table_main .mobile tr th:first-child {
    opacity: 0;
 }
  .pkg_resume .details .content .table_main .mobile tr th {
    border-radius: 10px;
    padding: 2px;
    width: 60px;
 }
  .pkg_resume .details .content .table_main .mobile tr th .headBlueLight {
    height: 45px;
    line-height: 45px;
    background-color: #ff7070 !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
 }
  .pkg_resume .details .content .table_main .mobile tr th .headBlue {
    height: 45px;
    line-height: 45px;
    background-color: #ce3636;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
 }
  .pkg_resume .details .content .table_main th {
    height: 20px;
    font-size: 16px;
    padding: 20px;
 }
  .pkg_resume .details .content .table_main th:first-child, .pkg_resume .details .content .table_main td:first-child {
    padding: 15px 10px 15px 35px;
 }
  .pkg_resume .details .content .table_main .con {
    font-size: 15px;
 }
  .pkg_resume .details .content .table_main .icon {
    font-size: 14px;
 }
  .pkg_resume .details .content .table_main .con_second {
    margin-left: 0;
 }
  .pkg_resume .details .content .table_price {
    margin-top: 25px;
 }
  .pkg_resume .details .content .table_price td:first-child {
    font-size: 17px;
    width: 110px;
 }
  .pkg_resume .details .content .table_price .regularPrice {
    font-size: 15px;
 }
  .pkg_resume .details .content .table_price .realPrice {
    font-size: 16px;
 }
  .pkg_resume .details .content .table_buy td .BuyButton {
    width: 90%;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
 }
  .pkg_resume .details .faq_title {
    font-size: 18px;
 }
  .pkg_resume .faq {
    margin: 0px auto 50px;
    padding: 0;
 }
  .pkg_resume .faq .advantage img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
 }
  .pkg_resume .faq .advantage h5 {
    width: 100%;
    height: auto;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 5px;
 }
  .pkg_resume .faq .advantage p {
    font-size: 14px;
    line-height: 22px;
    width: 250px;
 }
  .pkg_resume .faq .ant-collapse {
    background-color: #fff;
    margin-top: 30px;
 }
  .pkg_resume .bottom {
    height: auto;
 }
  .pkg_resume .bottom h2 {
    font-size: 18px;
 }
}
@media (max-width: 549px) and (max-width: 330px) {
  .pkg_resume .ResumeGuide .flex .r .zhinantu {
    width: 120px;
 }
}
.headBlue {
  position: relative;
  width: 99.1%;
  height: 53px;
  line-height: 53px;
  background-color: #ce3636;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  border-radius: 4px;
  margin: 0 auto;
}
.headBlueLight {
  // composes: headBlue;
  background-color: #fc6e6e;
}
.consultBtn {
  width: 173px;
  height: 60px;
  margin: 0 auto;
  margin-top: 67px;
  display: block;
  background: #d60c0c;
  border-radius: 3px;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  padding: 15px 34px;
  border-color: #ce3636;
}
@media (max-width: 549px) {
  .consultBtn {
    margin-top: 45px;
    width: 150px;
    height: 50px;
    font-size: 20px;
    padding: 0;
 }
}

.num-count{
  margin-top: 15px;
}

.popup_offerplus {
  .container {
    // margin: -15px -25px;
    background-color: #f9f9f9;
    padding: 20px 15px;
    .pay_menthod_wrap {
        display: flex;
    }
    @media (max-width: 1023px) {
        padding: 35px 15px 15px;
        .pay_menthod_wrap {
            display: block;
        }
        .left, .right {
            width: 100%;
        }
        .right {
            margin: 20px 0 0;
            .con {
                margin: 20px 0 30px;
            }
        }
        .right_click {
          margin: 20px 0 0;
          strong {
            color: #fff;
            border-bottom: 3px solid #fff;
          }
        }
    }
  } 
  .left {
      // width: 450px;
      background: #fff;
      padding: 0 25px;
      overflow: hidden;
      border-radius: 5px;
      strong{
        border-bottom: 3px solid #3d95f9;
      }
      &:hover{
        cursor: pointer;
        background: #286797;
      }
  }
  .left_click{
    background: #286797;
    padding: 0 25px;
    overflow: hidden;
    border-radius: 5px;
    strong {
      color: #fff;
      border-bottom: 3px solid #fff;
    }
  }
  .right {
      /* width: 380px; */
      background: #fff;
      margin: 0 0 0 30px;
      // width: 255px;
      padding: 0 25px;
      overflow: hidden;
      border-radius: 5px;
      strong{
        border-bottom: 3px solid rgb(9, 148, 9);
      }
      &:hover{
        cursor: pointer;
        background: #126a11;
      }
  }
  .right_click {
    background: #fff;
    margin: 0 0 0 30px;
    padding: 0 25px;
    // width: 255px;
    overflow: hidden;
    border-radius: 5px;
    background: #106a0e;
    strong {
      color: #fff;
      border-bottom: 3px solid #fff;
    }
  }
  .methodDes {
      color: #333;
      font-size: 14px;
      margin: 0 0 20px 10px;
      font-size: 15px;
      color: #666;
      i {
          font-size: 17px;
          position: relative;
          top: 1px;
          color: #000;
      }
      @media (max-width: 1023px) {
          margin: 10px 0 15px 0;
      }
  }
  .methodTitle {
      font-size: 18px;
      font-weight: 400;
      padding: 20px 0;
      position: relative;
      strong {
          display: inline-block;
          padding: 0 5px 5px 5px;
      }
      .wechat {
          color: rgb(9, 148, 9);
          font-size: 100px;
          position: absolute;
          opacity: 0.8;
          right: -45px;
          top: -9px;
      }
      .credit-card {
          color: #3d95f9;
          font-size: 80px;
          position: absolute;
          opacity: 0.8;
          right: -31px;
          top: -8px;
      }
  }
  .formAlert {
      padding: 8px 15px;
      background-color: #e6f7ff;
      margin: 15px 0 20px 0;
  }
  .formAlert2{ 
    padding: 8px 15px;
    background-color: #e6ffe9;
    margin: 15px 0 20px 0;
  }
  .choose {
      text-align: center;
      line-height: 40px;
      color: #333;
      font-size: 15px;
  }
  .popup_offerplus_success {
      composes: popup_offerplus_success;
      width: 600px!important;
      .img_wechat {
          display:block;
          margin:auto;
          width: 100%;
          max-width: 180px;
      }
      p {
          margin: 10px 0 -10px;
          font-size: 16px;
      }
      .container {
          margin: -25px;
      }
      @media (max-width: 1023px) {
          p {
              margin-bottom: 20px;
          }
      }
  }

}
@primary-color: #701919;