.payresult_wrap {
  padding-bottom: 80px;
  // background-image: linear-gradient(to bottom left,#bdd7ff,#ff6557);
  .result_wrap {
    margin: 130px auto 0;
    width: 600px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 0 5px #eee;

    .img_qrcode {
      width: 250px;
      display: block;
      margin: 20px auto;
      @media (max-width: 768px) {
        width: 200px;
      }
    }
    .ant-result-title{
        color: black;
    }
    .new_res {
      text-align: left;
      color: #555;
      strong {
          color: #333;
          text-decoration: underline;
      }
      .bottomDes {
          color: rgba(0, 0, 0, 0.45);
          font-size: 12px;
      }

      .main-ant-divider {
          margin: 24px 0 10px;
      }
    }

    @media (max-width: 1023px) {
        width: 90%;
    }
  }
}
@primary-color: #701919;