.appoinment_container{
  background-color: #eff1f4;
	width: 100%;
	height: 100vh; 
	padding: 100px 30px 30px;
	@media (max-width: 549px) {
		padding: 80px 10px 30px;
	}
  .ds160_form {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    @media (max-width: 549px) {
      padding: 20px 15px 15px;
      max-width: 500px;
    }
    .con_title {
      font-weight: 400;
      font-size: 18px;
    }
    .img_line{
      width: 88px;
      /* margin-bottom: 27px; */
      margin-bottom: 20px;
      @media (max-width: 549px) {
          width: 66px;
          /* margin-bottom: 27px; */
          margin-bottom: 27px;
      }
    } 
    .con_title_line{
      height: 3px;
      background-color: rgb(176, 28, 28);
      width: 85px;
      margin: 10px 0 20px;
    }
    .segment{
      margin-bottom: 20px;
    }
    .con_tip {
      color:  rgb(176, 28, 28);
      font-size: 14 px;
      margin-bottom: 3px;
      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }
  .form_content{
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 1px;
    .foot_btn_container {
      display: flex;
      justify-content: space-between;
    }
    .qrcode_pay {
      margin: 0 auto;
      text-align: center;
      img {
        max-width: 200px;
      }
    }
  }
}
@primary-color: #701919;