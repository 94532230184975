.xhs_container {
  padding: 72px 40px;
  max-width: 1440px;
  margin: 0 auto;
  min-height: calc(100vh - 320px);
  margin-bottom: 70px;
  @media (max-width: 549px) {
      padding: 72px 10px 10px 20px;
  }
  .xhs_header {
    // max-width: 1440px;
    justify-content: space-around;
    margin: auto;
    margin-bottom:20px;
    margin-top: 50px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    // justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    @media (max-width: 549px) {
        // flex-direction: column-reverse;
        width: 100%;
        flex-direction: row;
        margin-top: 30px;
    }
  }
  .xhs_img_event {
      margin-top: 30px;
      height: 120px;
      width: auto;
      cursor: pointer;
      @media (max-width: 549px) {
          height: 60px;
          margin-right: 20px;
          margin-top: 0px;
      }
  }
  .xhs_title {
      font-size: 56px;
      color: #555;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
      @media (max-width: 549px) {
          font-size: 25px;
          margin-top: 0px;
      }
  }
  .xhs_des {
      font-size: 16px;
      color: #a3a3a3;
      max-width: 800px;
      font-weight: 400;
      @media (max-width: 549px) {
          font-size: 15px;
      }
  }
  .xhs_main-container {
    // width: 1024px;
    padding-left: 25px;
    padding-right: 25px;
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    row-gap: 40px;
    // align-content: space-evenly;
    justify-content: space-evenly;
    // column-gap: 25px;
    max-width: 1440px;
    // margin: auto;
    height: auto;
    margin-top: 100px;
    @media (max-width: 549px) {
        width: 100%;
        margin-top: 30px;
    }
    .postCard {
      width: 265px;
      height: 345px;
      border-radius: 8px;
      // box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
      box-shadow: 0 4px 30px 0 rgba(238,242,245,.8);
      overflow: hidden;
      background-color: #fff;
      // margin-bottom: 50px;
      position: relative;
      cursor: pointer;
      &:hover {
          box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);
      }
      img{
        width: 265px;
        height: 345px;
        border-radius: 8px;
        padding: 10px;
      }
    }
  }
  .xhs_more {
    margin: auto;
    text-align: center;
    span{
      font-size: 17px;
      margin-top: 80px;
      color: #ce3636;
      cursor: pointer;
      &:hover{
        font-size: 18px;
      }
    }
  }
}
@primary-color: #701919;