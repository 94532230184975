.dashboardContainer {
  min-height: calc(100vh - 320px);
  background-color: #fff6f6;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
    height: 100%;
  }
  // .titleDes {
  //     font-size: 18px;
  //     font-weight: 500;
  //     line-height: 28px;
  //     color:  #fff;
  //     text-align: center;
  //     padding: 10px;
  //     @media (max-width: 767px) {
  //       font-size: 16px;
  //     }
  // }
  .mainContainer {
      width: 100%;
      max-width: 1440px;
      margin: 0px auto;
      margin-top: 72px;
      height: 100vh;
      // border: 1px saddlebrown solid;
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
      }
      .leftSide {
        // background-color: #5B8CFF;
        padding: 35px 50px;
        width: 380px;
        @media (max-width: 768px) {
           width: 100%;
           padding: 15px 35px;
        }
        .leftSideTitle {
          line-height: 50px;
          font-weight: 700;
          @media (max-width: 768px) {
            font-size: 28px;
            line-height: 35px;
          }
        }
        .leftSideTitleDesc {
          font-size: 16px;
          color: #808080;
          font-weight: 400;
          margin-bottom: 30px;
        }
        .time_slot {
          width: 100%;
          height: 50px;
          text-align: center;
          line-height: 44px;
          border: 2px solid #e8e8e8;
          border-radius: 10px;
          // background: #fff;
          margin-bottom: 22px;
          color: #989898;
          font-weight: 400;
          font-size: 15px;
          &:hover{
            border: 2px solid #f4dada;
            color: #595959;;
            background: #f4dada;
          }
          .city {
            float: left;
            margin-left: 20px;
            font-weight: 400;
            .anticon-clock-circle {
              margin-right: 8px;
            }
          }
          .time {
            float: right;
            margin-right: 20px;
            font-weight: 400;
          }
        }
        .time_slot_active {
          width: 100%;
          height: 50px;
          text-align: center;
          line-height: 44px;
          border: 2px solid #e8e8e8;
          border-radius: 10px;
          // background: #fff;
          margin-bottom: 22px;
          color: #989898;
          font-weight: 500;
          font-size: 15px;
          border: 2px solid #d60c0c;
          color: #d60c0c;
          background: #fff;
          &:hover{
            border: 2px solid #f4dada;
            color: #595959;
            background: #f4dada;
          }
          .city {
            float: left;
            margin-left: 20px;
            font-weight: 500;
            .anticon-clock-circle {
              margin-right: 8px;
            }
          }
          .time {
            float: right;
            margin-right: 20px;
            font-weight: 500;
          }
        }
        .divider {
          width: 110px !important;
          margin: 0 auto;
          .ant-divider {
            border-top: 2px solid #e8e8e8 !important;
          }
        }
        .btn_1 {
          width: 100%;
          height: 50px;
          margin: 0 auto;
          display: block;
          background: #d60c0c;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          color: #FFFFFF;
          padding: 15px 34px;
          border-color: #d60c0c;
          border-radius: 10px;
          box-shadow: 1px 1px 10px 1px #da8787;
        }
        .btn_2 {
          width: 100%;
          height: 50px;
          margin: 22px auto;
          display: block;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          color: #3a3a3a;
          padding: 15px 34px;
          border-color: #ebebeb;
          border-radius: 10px;
          box-shadow: 1px 1px 10px 1px #e0e0e0;
        }
      }
      .rightSide {
        flex: 1;
        // background-color: rgb(46, 93, 25);
        margin-top: 30px;
        .rightSideTop {
            display: flex;
            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }
            .calender {
                padding: 25px;
                width: 350px;
                height: 350px;
                background-color: #fff;
                border-radius: 10px;
                margin-right: 30px;
                box-shadow: 1px 1px 10px 1px #ebebeb;
                border: 1px solid #f3f2f2;
                .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
                background: #d60c0c;
                border: 1px solid #d60c0c;
                }
                .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
                border: 1px solid #d60c0c;
                }
                @media (max-width: 768px) {
                    margin: 0 auto;
                    width: 85%;
                    margin-bottom: 20px;
                    height: auto;
                 }
            }
            .helloBox {
                padding: 25px 35px;
                width: 660px;
                height: 350px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 1px 1px 10px 1px #ebebeb;
                border: 1px solid #f3f2f2;
                background-image: url('../../asset/img/dashboard_right.png');
                .p1 {
                margin-top: 25px;
                font-size: 16px;
                font-weight: 400;
                color: #6a6a6a;
                }
                .p2{
                font-size: 16px;
                font-weight: 400;
                margin-top: 3px;
                color: #6a6a6a;
                }
                .p3{
                font-size: 16px;
                font-weight: 400;
                margin-top: 25px;
                margin-bottom: 80px;
                color: #6a6a6a;
                }
                .btn_change {
                    width: 180px;
                    height: 40px;
                    // margin: 0 auto;
                    display: block;
                    background: #d60c0c;
                    font-size: 15px;
                    line-height: 10px;
                    text-align: center;
                    color: #FFFFFF;
                    padding: 15px 34px;
                    border-color: #d60c0c;
                    border-radius: 10px;
                    box-shadow: 1px 1px 2px 1px #da8787;
                }
                @media (max-width: 768px) {
                   background-image: none;
                   margin: 0 auto;
                   width: 85%;
                   margin-bottom: 20px;
                   height: auto;
                   .btn_change {
                    width: 100%;
                   }
                   .p1,.p2,.p3{
                    font-size: 14px;
                   }
                   .p3 {
                    margin-bottom: 40px;
                   }
                }
            }
        }
        .rightSideBottom {
            width: 1040px;
            height: 950px;
            background-color: #fff;
            border-radius: 10px;
            margin-top: 30px;
            box-shadow: 1px 1px 10px 1px #ebebeb;
            border: 1px solid #f3f2f2;
            padding: 25px 35px;
            .site-statistic-demo-card {
              margin-top: 20px;
              padding: 20px;
              background: #fafafa;
              @media (max-width: 768px) {
                display: none;
              }
            }
            @media (max-width: 768px) {
                margin: 0 auto;
                width: 85%;
                height: auto;
                margin-bottom: 30px;
                padding: 20px;
            }
        }
      }
  }
}
.login-form{
  width: 320px;
  margin: 0 auto;
  .site-form-item-icon{
      color: rgba(0,0,0,0.25);
  }
  // antd
  .ant-input-affix-wrapper-lg{
      border-radius: 10px;
      .ant-input-lg{
          font-size: 14px;
          // letter-spacing: 1px;
      }
  }
  .login-form-button{
      width: 100%;
      // color:#000;
  }
  .login-form-forgot{
      float: right;
      margin: -20px 0 10px;
      line-height: 40px;
      // color: #f06633; //
  }
}
@primary-color: #701919;