.appt_container {
    padding: 152px 40px;
    // max-width: 1460px;
    margin: 0 auto;
    min-height: calc(100vh - 320px);
    @media (max-width: 549px) {
        padding: 72px 10px 10px 20px;
    }
    background: #f0f2f5;
    .table_container{
        max-width: 1460px;
        margin: 0 auto;
                        
        .table_breadcrumb {
            margin-bottom: 15px;
            span {
                font-size: 16px;
            }
        }
        .table_chart {
            .table_chart_title {
                margin-bottom: 20px;
                font-size: 14px;
            }
            background: #fff;
            margin-bottom: 20px;
            padding: 20px 20px 5px 20px;
        }
        .table_h{
            width: 100%;
            // height: 75px;
            background: #fff;
            margin-bottom: 20px;
            padding: 20px 20px 5px 20px;
        }
        .table_c{
            padding: 25px;
            width: 100%;
            background: #fff;
            // height: 490px;
        }
    }



    .docs_header {
        width: 80%;
        margin: auto;
        margin-bottom:20px;
        margin-top: 30px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        @media (max-width: 549px) {
            flex-direction: column-reverse;
            width: 100%;
        }
    }
    .docs_img_event {
        margin-top: 30px;
        height: 330px;
        width: auto;
        @media (max-width: 549px) {
            height: 160px;
        }
    }
    .docs_title {
        font-size: 56px;
        color: #555;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
        @media (max-width: 549px) {
            font-size: 29px;
            margin-top: 0px;
        }
    }
    .docs_des {
        font-size: 16px;
        color: #a3a3a3;
        max-width: 800px;
        font-weight: 400;
        @media (max-width: 549px) {
            font-size: 16px;
        }
    }
    .plan{
        width: 1024px;
        padding-left: 25px;
        padding-right: 25px;
        margin: auto;
        position: relative;
        @media (max-width: 549px) {
            width: 100%;
        }
    }
    .con_title{
        color:#3D3D3D;
        font-size: 29px;
        font-weight: 400;
        line-height: 70px;
        margin-bottom: 0;
        @media (max-width: 549px) {
            font-size: 24px;
            line-height: 40px;
        }
    }
    .img_line{
        width: 88px;
        /* margin-bottom: 27px; */
        margin-bottom: 57px;
        @media (max-width: 549px) {
            width: 66px;
            /* margin-bottom: 27px; */
            margin-bottom: 47px;
        }
    }
    .btn_getplan{
        font-weight: 400;
        width: 150px;
        height: 50px;
        font-size: 16px;
        line-height: 28px;
        padding: 11px 16px;
        color: #fff;
        border-color: #5d96ed;
        background-color: #5d96ed;
        border-radius: 3px;
        &:hover{
            border-color: #5d96ed;
            color: #5d96ed;
            background-color: #fff;
        }     
    }
    .plan_con{
        cursor: pointer;
        &:hover{
            scale: 1.03;
            transition: 0.8s;
            box-shadow: 0 0 30px 0 rgba(159,159,159,0.5);
        }
        width: 960px;
        box-shadow: 0 0 5px 0 rgba(159,159,159,0.5);
        border-radius: 10px;
        margin-bottom: 54px;
        position: relative;
        padding-left: 105px;
        padding-right: 105px;
        padding-top: 65px;
        background-color: #fff;
        height: fit-content;
        padding-bottom: 45px;
        @media (max-width: 549px) {
            max-width: 100%;
            padding: 50px 20px;
        }
        h3{
            position: absolute;
            top: -3px;
            left: 72px;
            font-size: 20px;
            font-weight: 400;
            color: #fff;
            line-height: 28px;
            color: rgb(248, 230, 184);
            @media (max-width: 549px) {
                font-size: 18px;
                top: -5px;
                left: 32px;
            }
        }
        h2{
            font-size: 23px;
            font-weight: 400;
            color: #4C4C4C;
            line-height: 50px;
            margin-top: 0;
            margin-bottom: 20px;
            @media (max-width: 549px) {
                font-size: 21px;
                margin-bottom: 10px;
                font-size: 20px;
            }
        }
        h5{
            margin-top: 25px;
            margin-bottom: 5px;
            font-size: 16px;
            color: #4C4C4C;
            font-weight: 400;
            line-height: 21px;
            @media (max-width: 549px) {
                font-size: 15px;
                margin-top: 15px;
            }
        }
        p{
            line-height: 27px;
            font-size: 14px;
            font-weight: 400;
            color: #4C4C4C;
            margin-bottom: 0;
        }
        .img_cover{
            width: 235px;
            height: 57px;
            position: absolute;
            top: -15px;
            left: 25px;
            @media (max-width: 549px) {
                width: 186px;
                height: 47px;
                top: -15px;
                left: 2px;
            }
        }
        .img_corner{
            height: 174px;
            position: absolute;
            bottom: 0;
            right: 0;
            @media (max-width: 549px) {
                height: 124px;
            }
        }
        .file_type {
            position: absolute;
            right: 30px;
            top: 20px;
            font-size: 40px;
            color:  #ce3636;
            @media (max-width: 549px) {
                font-size: 25px;
                right: 15px;
            }
        }
        .img_corner_mobile{
            display: none;
        }
        .img_greyline_plan{
            display: none;
        }
        .has_bought{
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            color: #ce3636;
            margin-right: 6px;
            margin-bottom: 6px;
            background-color: #fce5e5;
            padding: 6px 12px;
            border-radius: 4px;
            position: absolute;
            right: 20px;
            bottom: 10px;
            @media (max-width: 549px) {
                right: 10px;
            }
        }
        div {
            margin-top: 10px;
        }
        .docs_price_now{
            font-size: 30px;
            margin-right: 20px;
            color:#ce3636;
            font-weight: 400;
            @media (max-width: 549px) {
                font-size: 25px;
                margin-right: 15px;
            }
        }
        .docs_price_original{
            font-size: 20px;
            color: #979797;
            text-decoration: line-through;
            @media (max-width: 549px) {
                font-size: 15px;
            }
        }
    }
}
@primary-color: #701919;