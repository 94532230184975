.wrap {
  width: 600px;
  margin: 0 auto 0;
  padding-top: 100px;
  @media (max-width: 1023px) {
      width: 90%;
  }
}
.btn_pay {
  display: block;
  margin: 30px auto 0;
}
.payresult_wrap {
  padding-bottom: 80px;
  .img_qrcode {
      width: 150px;
      display: block;
      margin: 20px auto;
  }
}
.result_wrap {
  margin: 130px auto 0;
  width: 600px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 0 5px #eee;
 :global{
     .main-ant-result-title{
         color: black;
     }
 }

  @media (max-width: 1023px) {
      width: 90%;
  }
}

.new_res {
  text-align: left;
  color: #555;
  strong {
      color: #333;
      text-decoration: underline;
  }
  .bottomDes {
      color: rgba(0, 0, 0, 0.45);
      font-size: 12px;
  }
  :global {
      .main-ant-divider {
          margin: 24px 0 10px;
      }
  }
}

.total_amount {
  font-size: 32px;
}

.loadwrap {
  text-align: center;
  margin-top: calc((100vh - 450px) / 2);
  position: fixed;
  width: 100%;
}
.loadspin {
  font-size: 40px;
  color: #3d95f9;
}
.loaddes {
  font-size: 16px;
  margin: 30px 0 5px;
}
.loadcount {
  color: #999;
  font-size: 14px;
}
.wechat_title {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin-top: 0px;
}
.btn_start_course {
  font-size: 16px;
  line-height: 22px;
  /* color: #327DFF; */
  font-weight: 400;
  border: 1px solid #327DFF;
  border-radius: 25px;
  width: 150px;
  height: 45px;
  display: block;
  margin: 30px auto;
  background-color: #327DFF;
  color: #fff;
}
.wechatWrap {
  min-height: 300px;
  padding: 160px 0;
  text-align: center;
  @media (max-width: 992px) {
      padding: 0;
      margin-top: 120px;
  }
  .qrcode {
      margin: 0 auto;
      width: 180px;
  }
  .qrdes {
      margin: 15px 0 25px;
  }
  .qrwrap {
      border: 1px solid #eee;
      width: 260px;
      margin: 0 auto;
      padding: 35px 0 0;
      box-shadow: 0 0 5px #eee;
  }
}
.achWrap {
  width: 800px;
  min-height: calc(100vh - 320px);
  margin: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  .imgPackage {
      width: 100px;
      margin-top: 25px;
      margin-bottom: 25px;
  }
  .pkgName {
      display: inline-block;
      margin-left: 25px;
      vertical-align: middle;
      p {
          font-weight: 400;
          line-height: 28px;
      }
  }
  table {
      width: 100%;
      tr {
          border-bottom: 1px solid #e8e8e8;
      }
  }
  h2 {
      font-size: 16px;

  }
  p {
      color: rgba(0,0,0,.65);
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 0;
      font-weight: 400;
  }
  .paymentMethod {
      display: inline-block;
      margin-left: 0;
      font-size: 15px;
      font-weight: 400;
  }
  .paymentMethodCon {
      margin-top: 25px;
      margin-bottom: 25px;
  }
  .cartIcon {
      font-size: 32px;
      vertical-align: middle;
      margin-right: 10px;
  }
  .imgAchLogo {
      width: 28px;
      vertical-align: top;
  }
  .imgCreditLogo{
      font-size: 28px;
      vertical-align: middle;
      margin-left: 5px;
  }
  @media (max-width: 1023px) {
      width: 90%;
      h2 {
          font-size: 16px;
      }
      p {
          font-size: 14px;
      }
      .paymentMethod {
          font-size: 15px;
          display: block;
          margin-top: 10px;
      }
      .imgPackage {
          width: 60px;
          margin-top: 0;
          margin-bottom: 0;
      }
      .pkgName {
          margin: 25px 0 25px 10px;
      }
      :global {
          .main-ant-radio-wrapper {
              display: block;
          }
      }
  }
}

.spin_layout{
  margin-top: 50px !important;
}
@primary-color: #701919;