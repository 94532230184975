.regWrapper{
    margin: 110px auto 0;
    .reg_title{
        padding-top: 30px;
        text-align: center;
        font-weight: 300;
        h2{
            font-size: 40px;
            margin:0;
            font-weight: 100;
            strong{
                font-weight: 600;
            }
        }
        .reg_title_des{
            margin: 10px 0 20px;
            font-size: 17px;
            color: #666;
        }
    }
    .reg_content{
        width: 350px;
        margin: 0 auto 35px;
        .regFormWrapper{
            .reg-form{
                .site-form-item-icon{
                    color: rgba(0,0,0,0.25);
                }
                // antd
                .ant-input-affix-wrapper-lg{
                    border-radius: 5px;
                    .ant-input-lg{
                        font-size: 14px;
                        // letter-spacing: 1px;
                    }
                }
                .reg-form-button{
                    width: 100%;
                }
            }
        }
        .tologin{
            width: 100%;
            text-align: center;
            margin: 20px 0;
            font-size: 15px;
        }
    }
}
@primary-color: #701919;