.landing_wrapper{
  // background-color: rgb(61, 61, 28);
  height: 100vh;
  display: flex;
  // flex-direction: row-reverse;
  .landing_left {
    position: relative;
    @media (max-width: 800px) {
      display: none;
    }
    // max-width: 500px;
    // background-color: blanchedalmond;
    // height: 100vh;
    
    // background-size:  100vh;
    // background-repeat: no-repeat;
    // background: fixed;
    // min-width: 400px;
    background-image: url('../../asset/img/land2.png');
    // background-repeat: no-repeat;
    // background-position-x: center;
    @media (max-width: 1920px) {
      background-size: 1355px;
    }
    background-size: 2000px;
    // background-position-y: bottom;
    // background-position-x: right;
    .slogan {
      margin-top: 300px;
      margin-left: 80px;
      max-width: 800px;
      margin-right: 80px;
      color: #292929;
      .slogantop{
        width: 100px;
        border-top: 5px solid #c83636;
      }
    }
    width: 55%;
    .leftimg{
      width: 450px;
      position: absolute;
      right: 100px;
      bottom: 10px;
      // height: 100%;
      z-index: 1;
    }
  }
  .landing_right {
    @media (max-width: 800px) {
      width: 100%;
      padding: 0px;
    }
    width: 45%;
    background-color: #991B1E;
    box-shadow: 1px 22px 60px rgba(0, 0, 0, 0.5);
    .right_wrapper {
      max-width: 850px;
      padding-left: 40px;
      padding-right: 40px;
      margin: 0 auto;
      margin-top: 30px;
      @media (max-width: 800px) {
        padding-left: 25px;
        padding-right: 25px;
        margin-top: 15px;
      }
      .heading {
        font-size: 55px;
        font-weight: 700;
        margin: 0;
        color: white;
        line-height: 60px;
        margin-left: 8px;
        @media (max-width: 800px) {
          font-size: 40px;
        }
      }
      h1 {
        font-size: 55px;
        font-weight: 700;
        margin: 0;
        color: white;
        line-height: 60px;
        margin-left: 8px;
        @media (max-width: 800px) {
          font-size: 40px;
        }
      }
      .logo {
        width: 80px;
        display: block;
      }
      .bottom {
        margin-top: 50px;
        font-family: Human BBY Web, Roboto, Arial, sans-serif;
        display: flex;
        text-align: center;
        justify-content: space-around;
        // justify-content: space-evenly;
        // max-width: 558px;
        .usa {
          display: block;
          text-decoration: none;
          opacity: 0.55;
          &:hover{
            opacity: 1;
          }
          // background-color: rgb(23, 130, 201);
          img {
            width: 180px;
            border-radius: 50%;
            // height: 100%;
          }
          span{
            margin-top: 15px;
            display: block;
            font-size: 20px;
            font-weight: 600;
            color: white;
            opacity: 1; 
          }
        }
        .canada {
          display: block;
          text-decoration: none;
          // border-radius: 50%;
          // background-color: brown;
          opacity: 0.55;
          &:hover{
            opacity: 1;
          }
          img{
            width: 180px;
            border-radius: 50%;
            // height: 100%;
          }
          span{
            margin-top: 15px;
            display: block;
            font-size: 20px;
            font-weight: 600;
            color: white;
          }
        }
      }
      .horizontal-rule {
        width: 100%;
        margin: 32px 0;
        height: 1px;
        margin: 24px 0;
        background: #c83636;
      }
      h3 {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
      span {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
@primary-color: #701919;