.no_match_container{
    position: relative;
    height: 100vh;
    .no_match{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}
@primary-color: #701919;