.loginWrapper{
    width: 100%;
    margin: 100px auto 0;
    height: auto;
    .login_title{
        padding-top: 30px;
        text-align: center;
        font-weight: 300;
        h2{
            font-size: 40px;
            margin:0;
            font-weight: 100;
            strong{
                font-weight: 600;
            }
        }
        .login_title_des{
            margin: 10px 0 20px;
            font-size: 17px;
            color: #666;
        }
    }
    .login_content{
        width: 360px;
        margin: 0 auto 35px;
        .loginFormWrapper{
            .login-form{
                .site-form-item-icon{
                    color: rgba(0,0,0,0.25);
                }
                // antd
                .ant-input-affix-wrapper-lg{
                    border-radius: 5px;
                    .ant-input-lg{
                        font-size: 14px;
                        // letter-spacing: 1px;
                    }
                }
                .login-form-button{
                    width: 100%;
                    // color:#000;
                }
                .login-form-forgot{
                    float: right;
                    margin: -20px 0 10px;
                    line-height: 40px;
                    color: #f06633; //
                }
            }
        }
        .thridPatry_title{
            color: #bbb;
            font-weight: 300;
            font-size: 14px;
            letter-spacing: 1px;
        }
        .thridPatryWrapper{
            display: flex;
            justify-content: space-between;
            .signinGoogle{
                width:45%;
                height: 40px;
                line-height: 40px;
                box-shadow: 0 1px 4px rgba(0,0,0,0.3);
                border-radius: 3px;
                margin: 15px 0;
                padding-left: 25px;
                color: rgba(0,0,0,0.54);
                background: #fff;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                overflow: hidden;
                &:hover{
                  background-color: #f9f9f9;
                }
                .google{
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  margin-right: 16px;
                  margin-bottom: 5px;
                }
            }
        }
        .toRegister{
            // width: 100%;
            margin: 20px 0;
            font-size: 15px;
            text-align: center;
            .reg_link{
                color: #f06633;
            }
        }
    }

}
@primary-color: #701919;