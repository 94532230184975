.faqContainer {
  min-height: calc(100vh - 320px);
  .titleCon {
      margin-top: 72px;
      width: 100%;
      height: 590px;
      background-image: url('../../asset/img/faq_background.png');
    //   background-color: #5B8CFF;
    //   background-color: #ea3d3d;
      padding-top: 220px;
      background-size: 1600px auto;
      background-repeat: no-repeat;
      @media (max-width:549px) {
        height:  520px;
      }
  }
  .title {
      font-size: 40px;
      line-height: 47px;
    //   font-size: 36px;
      font-weight: 700;
    //   line-height: 50px;
      color:  #fff;
      text-align: center;
  }
  .titleDes {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color:  #fff;
      text-align: center;
      padding: 10px;
      @media (max-width: 767px) {
        font-size: 16px;
      }
  }
  .faqQuestions {
      width: 100%;
      max-width: 900px;
      margin: 40px auto;
      .faq_answer_qr_code{
          width: 100px;
          height: 100px;
      }
  }
  .faq_collapse {
    .ant-collapse-item {
        width: 100%;
        border: none;
        padding: 8px 15px;
        margin: 0 0 10px;
        box-shadow: 0 0 2px rgba(0,0,0,.15);
        border-radius: 2px;
        background: #fff;
        position: relative;
    }
    .ant-collapse-arrow {
        color: #5B8CFF !important;
    }
    .ant-collapse-content {
        padding-left: 25px;
    }
    .ant-collapse-header {
        font-size: 16px;
    }
  }
  .otherQuestion {
      padding-bottom: 40px;
      text-align: center;
      margin-top: 48px;
      margin-bottom: 100px;
    //   min-height: calc(100vh - px);
  }
  .emailAddress {
      margin-left: 5px;
      font-size: 15px;
  }
  @media (min-width: 1601px) {
      .titleCon {
          background-size: 100% auto;
      }
  }
  @media (max-width: 767px) {
      .faq_collapse {
        .ant-collapse-item {
            padding: 16px 20px 16px 0;
            font-size: 16px;
            line-height: 24px;
        }
        .ant-collapse-content-box {
            padding: 16px 0 0;
        }
      }
      .faqQuestions {
          padding: 0 25px;
      }
      .otherQuestion {
          padding: 0 25px;
      }
  }
}
@primary-color: #701919;